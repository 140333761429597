import { React } from "react";
// import { FaAngleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { GiHamburgerMenu } from "react-icons/gi";

const Navemployee = () => {
  const navigate = useNavigate();
  const handleProf = () => {
    navigate("/Frame-10");
  };
  const frame11 = () => {
    navigate("/Frame-10");
  };
  const handleProfile = () => {
    navigate("/Section-6");
  };
  const handleDashboard = () => {
    navigate("/");
  };

  return (
    <div className="w-full xs:h-14 h-16 text-white flex justify-between items-center mx-auto bg-primary-blue font-inter ">
      <div className=" h-12 w-12 rounded-full flex items-center cursor-pointer shadow-xl">
        <img src="/hirersLogo.png" alt="logo" onClick={handleDashboard} />
      </div>

      <div className="flex items-center justify-evenly">
        <span
          className="block sm:hidden md:h-10 md:pr-3 pt-2 cursor-pointer mr-7  text-base"
          onClick={frame11}
        >
          Search Job
        </span>
        <span className="sm:hidden flex sm:pr-3 pt-1">
          <div
            className="  h-10 w-10  mr-4 rounded-full"
            onClick={handleProfile}
          >
            <img
              src="/profile-img.webp" // Corrected path to the image in the public folder
              alt="menu icon"
              className="h-full w-full rounded-full"
            />
          </div>
        </span>
        <span
          className="hidden sm:block pr-3 pt-1 cursor-pointer mr-24 text-xl"
          onClick={handleProf}
        >
          Search Job
        </span>
        <div className="relative hidden sm:flex items-center bg-white rounded-xl px-4 py-1 mb-2 mt-2 mr-8 cursor-pointer">
          <p
            className="cursor-pointer text-primary-blue text-xl"
            onClick={handleProfile}
          >
            Your Profile
          </p>

          <div className=" h-8 w-8 ml-4  ">
            <GiHamburgerMenu className="text-black h-full w-full" />
          </div>
        </div>
        <div className="sm:mr-4">
          <span
            className="material-symbols-outlined text-[#4285F4]  mr-4 mt-2
    cursor-pointer 
    text-[30px] 
    float-right 
    md:text-[40px] 
    sm:text-[30px] 
    sm:float-none 
    sm:block 
    sm:mx-auto"
            onClick={() => {
              Swal.fire({
                title: "Are you sure you want to log out?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, log out",
                cancelButtonText: "Cancel",
              }).then((result) => {
                if (result.isConfirmed) {
                  // Clear local storage items
                  localStorage.removeItem("type");
                  localStorage.removeItem("token");

                  // Show success message
                  Swal.fire({
                    title: "Logged out successfully",
                    icon: "success",
                    timer: 2000,
                    showConfirmButton: false,
                  });

                  // Redirect to home page
                  navigate("/");
                }
              });
            }}
          >
            logout
          </span>
        </div>
      </div>
    </div>
  );
};

export default Navemployee;
