import { useEffect, useState } from "react";
import { load } from "@cashfreepayments/cashfree-js";
import { useSearchParams } from "react-router-dom";

function Checkout() {
  const [cashfree, setCashfree] = useState(null);
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("order_id");

  const handlePaymentCompletion = async (orderId) => {
    console.log(`Handling payment completion for order_id: ${orderId}`);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PORT}/fetch-payment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ orderId }),
        }
      );
      console.log("Response:", response);
      if (!response.ok) {
        throw new Error(
          `Failed to fetch payment status: ${response.statusText}`
        );
      }

      const paymentData = await response.json();
      console.log("Payment Status:", paymentData);

      // Implement your logic for processing paymentData
      // Example: Move data from temporary to final collection
      if (paymentData.status === "SUCCESS") {
        console.log("Payment successful! Processing further...");
        alert("Payment successful! Processing further...");
        // Add logic to move data from temporary to final collection
      } else if (paymentData.status === "FAILED") {
        console.log("Payment failed. Please try again.");
        alert("Payment failed. Please try again.");
      } else if (paymentData.status === "PENDING") {
        console.log("Payment is pending. Please wait for confirmation.");
        alert("Payment is pending. Please wait for confirmation.");
      } else {
        console.warn(
          "Payment not successful. Check paymentData for more info."
        );
        alert("Payment not successful. Check paymentData for more info.");
      }
    } catch (error) {
      console.error("Error fetching payment status:", error.message);
      alert("Error fetching payment status. Check console for more info.");
    }
  };

  useEffect(() => {
    if (orderId) {
      // Handle the case when order_id is present
      handlePaymentCompletion(orderId);
    }
  }, [orderId]);

  // Initialize Cashfree SDK
  useEffect(() => {
    const initializeSDK = async () => {
      const sdk = await load({
        mode: "sandbox",
      });
      setCashfree(sdk);
    };
    initializeSDK();
  }, []);

  const doPayment = async () => {
    if (!cashfree) {
      console.error("Cashfree SDK not loaded.");
      return;
    }

    console.log("Cashfree SDK loaded:", cashfree);

    // Create the payment order by calling the backend API
    const orderResponse = await fetch(
      `${process.env.REACT_APP_PORT}/create-order`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          order_amount: 100.0, // You can update this with the actual order amount
          order_currency: "INR",
          customer_id: "devstudio_user",
          customer_phone: "8474090589",
        }),
      }
    );

    if (!orderResponse.ok) {
      console.error("Error creating order:", orderResponse);
      return;
    }

    const orderData = await orderResponse.json();
    const { paymentSessionId, orderId } = orderData; // Destructure from backend response

    console.log("Payment Session ID:", paymentSessionId);
    console.log("Order ID:", orderId);
    // Configure the checkout options
    const checkoutOptions = {
      paymentSessionId,
      redirectTarget: "_self", // Redirect in the same window
    };
    cashfree.checkout(checkoutOptions);
    // Launch the Cashfree checkout
    // cashfree.checkout(checkoutOptions).then((result) => {
    //   if (result.error) {
    //     // This will be true whenever user clicks on close icon inside the modal or any error happens during the payment
    //     console.log(
    //       "User has closed the popup or there is some payment error, Check for Payment Status"
    //     );
    //     console.log(result.error);
    //   }
    //   if (result.redirect) {
    //     // This will be true when the payment redirection page couldnt be opened in the same window
    //     // This is an exceptional case only when the page is opened inside an inAppBrowser
    //     // In this case the customer will be redirected to return url once payment is completed
    //     console.log("Payment will be redirected");
    //   }
    //   if (result.paymentDetails) {
    //     // This will be called whenever the payment is completed irrespective of transaction status
    //     console.log("Payment has been completed, Check for Payment Status");
    //     console.log(result.paymentDetails.paymentMessage);
    //   }
    // });
  };

  // Fetch payment details after orderId changes

  return (
    <div className="row">
      <p>Click below to open the checkout page in the current tab</p>
      <button
        type="button"
        className="btn btn-primary"
        id="renderBtn"
        onClick={doPayment}
      >
        Pay Now
      </button>
    </div>
  );
}

export default Checkout;
