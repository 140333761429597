import React from "react";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const Refund = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen p-5">
      <div className="max-w-4xl mx-auto p-4 relative">
        {/* Back Button */}
        <button
          onClick={() => navigate('/')}
          className="absolute sm:top-4 top-0 sm:left-4 left-1 flex items-center gap-2 text-primary-blue text-lg sm:text-2xl "
        >
          <IoArrowBackCircleSharp className="text-2xl sm:text-3xl" />
          <span>Back</span>
        </button>

        {/* Refund Policy Content */}
        <h1 className="text-2xl sm:text-4xl font-bold mb-4 text-center sm:mt-4">
          Cancellation & Refund Policy
        </h1>
        <p className="text-gray-600 text-sm mb-2">
          Last updated on: 02-10-2024 16:16:19
        </p>
        <p className="text-gray-700 leading-relaxed">
          HIRERS believes in helping its customers as far as possible, and has
          therefore a liberal cancellation policy. Under this policy:
        </p>
        <ul className="list-disc pl-6 mt-4 space-y-2 text-gray-700">
          <li>
            Cancellations will be considered only if the request is made
            immediately after placing the order. However, the cancellation
            request may not be entertained if the orders have been communicated
            to the vendors/merchants and they have initiated the process of
            shipping them.
          </li>
          <li>
            HIRERS does not accept cancellation requests for perishable items
            like flowers, eatables etc. However, refund/replacement can be made
            if the customer establishes that the quality of product delivered is
            not good.
          </li>
          <li>
            In case of receipt of damaged or defective items please report the
            same to our Customer Service team. The request will, however, be
            entertained once the merchant has checked and determined the same at
            his own end. This should be reported within Only same day days of
            receipt of the products. In case you feel that the product received
            is not as shown on the site or as per your expectations, you must
            bring it to the notice of our customer service within Only same day
            days of receiving the product. The Customer Service Team after
            looking into your complaint will take an appropriate decision.
          </li>
          <li>
            In case of complaints regarding products that come with a warranty
            from manufacturers, please refer the issue to them. In case of any
            Refunds approved by the HIRERS, it’ll take 6-8 Days days for the
            refund to be processed to the end customer.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Refund;
