import React, { useEffect, useState } from "react";
import HorizontalScroll from "./Desktop1/companys_scroll";
import { auth, googleProvider, facebookProvider } from "./firebase";
import { signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";
// import Navbar1 from "./Sections/Navbar-3&5/Navbar2";
// import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import CSS for Toastify
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import axios from "axios";

const Desktop3 = () => {
  const companies = [
    "Zomato_logo.png",
    "rapido.png",
    "paytm.png",
    "uber.png",
    "samsung.png",
    "techmahindra.png",
    "swiggy.png",
    "daikin.png",
    "airtel.jpg",
    "webvi.png",
    "tp.png",
    "policybazaar.jpeg",
    "moneylink.png",
    "ambar skytech.png",
  ];
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const type = localStorage.getItem("type");

  useEffect(() => {
    if (token && type === "employee") {
      navigate("/Frame-18");
    }
  }, [type, token, navigate]);

  // const handleGoogleSignIn = async () => {
  //   try {
  //     const result = await signInWithPopup(auth, googleProvider);
  //     const user = result.user;
  //     // console.log("Google Sign-In successful: ", user);

  //     // Store UID and type in localStorage
  //     localStorage.setItem("type", "employee");

  //     // Fetch token from server and store in localStorage
  //     const getTokenFromServer = async () => {
  //       try {
  //         const response = await fetch(
  //           `${process.env.REACT_APP_PORT}/generateToken`,
  //           {
  //             method: "POST",
  //             headers: {
  //               "Content-Type": "application/json",
  //             },
  //             body: JSON.stringify({ uid: user.uid, type: "employee" }), // Pass UID or any other necessary data
  //           }
  //         );

  //         if (!response.ok) {
  //           throw new Error("Failed to retrieve token");
  //         }

  //         const data = await response.json();
  //         localStorage.setItem("token", data.token);
  //       } catch (error) {
  //         console.error("Error fetching token:", error);
  //         throw error; // Rethrow to be caught in the outer try-catch
  //       }
  //     };

  //     await getTokenFromServer(); // Wait for token retrieval to complete
  //     toast.success("Google Sign-In successful!");
  //     navigate("/Frame-18");
  //   } catch (error) {
  //     console.error("Error during Google Sign-In: ", error);
  //     toast.error("Google Sign-In failed. Please try again.");
  //   }
  // };

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      // testing code
      const idToke = await user.getIdTokenResult();
      if (!idToke.claims.role) {
        await setRoleOnBackend(user.uid, "employee"); // Set role as "employee" for new users
        await getTokenFromServer(user.uid, "employee"); // Reuse the external function
        toast.success("Google Sign-In successful!");
        navigate("/Frame-18");
      } else {
        const role = idToke.claims.role;
        console.log("Role detected: ", role);
        if (role === process.env.REACT_APP_TYPE_EMPLOYEE) {
          await getTokenFromServer(
            user.uid,
            process.env.REACT_APP_TYPE_EMPLOYEE
          ); // Reuse the external function
          toast.success("Google Sign-In successful!");
          navigate("/Frame-18");
          // Redirect to admin dashboard
        } else if (
          role === process.env.REACT_APP_TYPE_EMPLOYEE ||
          role === process.env.REACT_APP_TYPE_ADMIN
        ) {
          toast.error("Access Denied for this email");
        } else {
          toast.error("Invalid role detected. Please contact support.", role);
        }
      }

      // Fetch token from server and store in localStorage
    } catch (error) {
      console.error("Error during Google Sign-In: ", error);
      toast.error("Google Sign-In failed. Please try again.");
    }
  };

  const getTokenFromServer = async (uid, role) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PORT}/generateToken`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ uid, type: role }), // Pass UID and role as necessary data
        }
      );

      if (!response.ok) {
        throw new Error("Failed to retrieve token");
      }

      const data = await response.json();
      localStorage.setItem("token", data.token);
    } catch (error) {
      console.error("Error fetching token:", error);
      throw error; // Rethrow to be caught in the outer try-catch
    }
  };

  const setRoleOnBackend = async (uid, role) => {
    try {
      await fetch(`${process.env.REACT_APP_PORT}/setRole`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ uid, role }),
      });
    } catch (error) {
      console.error("Error setting role:", error);
    }
  };

  const handleFacebookSignIn = async () => {
    try {
      // console.log("Facebook Sign-In initiated");
      const result = await signInWithPopup(auth, facebookProvider);
      const user = result.user;
      const response = await fetch(
        `${process.env.REACT_APP_PORT}/generateToken`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ uid: user.uid, type: "employee" }), // Pass UID or any other necessary data
        }
      );
      const data = await response.json();

      localStorage.setItem("type", "employee");
      localStorage.setItem("token", data.token);
      toast.success("Facebook Sign-In successful!");
    } catch (error) {
      console.error("Error during Facebook login:", error.message);
      toast.error("Facebook Sign-In failed. Please try again.");
    }
  };

  // const secretKey = process.env.REACT_APP_SECRET_KEEY; // Ensure this matches the server's key

  // function decryptPhoneNumber(encryptedPhoneNumber) {
  //   const bytes = CryptoJS.AES.decrypt(encryptedPhoneNumber, secretKey);
  //   return bytes.toString(CryptoJS.enc.Utf8);
  // }

  const [countryCode, setCountryCode] = useState("+91");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);

  const handleVerifyOtp = async (e) => {
    e.preventDefault();

    if (!otp) {
      toast.error("Please enter the OTP");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PORT}/verify-otp`,
        {
          phoneNumber: countryCode + phoneNumber,
          otp: otp,
        }
      );

      if (response.data.message === "OTP verified successfully") {
        const responso = await fetch(
          `${process.env.REACT_APP_PORT}/generateToken`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              uid: response.data.phoneNumber,
              type: "employee",
            }), // Pass UID or any other necessary data
          }
        );
        const data = await responso.json();
        localStorage.setItem("token", data.token);
        toast.success("OTP verified successfully!");
        // const decryptedPhone = decryptPhoneNumber(response.data.phoneNumber);
        // console.log(decryptedPhone);
        localStorage.setItem("type", "employee");
        navigate("/Frame-18");
      } else {
        toast.error("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("There was an error verifying the OTP.");
    }
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();

    if (!phoneNumber || phoneNumber.length !== 10) {
      toast.error("Please enter a valid 10-digit phone number");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PORT}/send-otp`,
        {
          phoneNumber: countryCode + phoneNumber,
        }
      );

      if (response.data.message === "OTP sent successfully") {
        toast.success("OTP sent successfully!");
        setOtpSent(true);
      } else {
        toast.error("Failed to send OTP.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error("There was an error sending the OTP.");
    }
  };

  return (
    // <div>
    //   <Navbar1 />
    //   <div>
    //     {/* <ToastContainer
    //     position="top-right"
    //     autoClose={3000}
    //     hideProgressBar={false}
    //     newestOnTop={false}
    //     closeOnClick
    //     rtl={false}
    //     pauseOnFocusLoss
    //     draggable
    //     pauseOnHover
    //     theme="light"
    //   /> */}
    //     <div
    //       className="flex flex-col bg-white mt-2 mx-5 lg:mx-20 h-auto"
    //       style={{ fontFamily: "Inter" }}
    //     >
    //       {/* Login/SignUp Page */}
    //       <div className="flex flex-col md:flex-row justify-between ">
    //         <div className="text-left flex flex-col justify-between">
    //           <div style={{ color: "#393285" }}>
    //             <h1 className="text-5xl md:text-7xl font-semibold">
    //               Powering Your
    //             </h1>
    //             <h1 className="text-5xl md:text-7xl font-semibold">
    //               Hiring Success!!
    //             </h1>
    //             <p className="text-xl md:text-2xl mt-2 text-black font-semibold">
    //               Log in now and experience the future of hiring with Hirers.
    //             </p>
    //           </div>

    //           <div className="text-xl md:text-2xl font-semibold md:block hidden">
    //             <p>Admin SignIn?</p>
    //             <button
    //               className="text-blue-700"
    //               onClick={() => navigate("/admin")}
    //             >
    //               Click Here
    //             </button>
    //           </div>
    //         </div>

    //         {/* Employer LogIn Section */}
    //         <div className="flex justify-center w-full  md:w-1/3 md:h-2/3 md:mt-0 mr-20 mt-8 mb-8 h-72 order-3">
    //           <div
    //             className=" rounded-2xl mt-8 shadow-lg w-full md:p-12 p-6"
    //             style={{ background: "#DFDCF7" }}
    //           >
    //             <h2 className="text-2xl md:text-3xl font-semibold ">
    //               Employer LogIn/SignUp
    //             </h2>
    //             {/* <form className="space-y-4">
    //             <div className="flex space-x-2">
    //               <input
    //                 type="text"
    //                 placeholder="+91"
    //                 className="border p-2 rounded-lg w-16 font-semibold md:h-14 text-2xl"
    //               />
    //               <input
    //                 type="text"
    //                 placeholder="Mobile number"
    //                 className="border p-2 rounded-lg w-full font-semibold md:h-14 text-2xl"
    //               />
    //             </div>
    //             <button
    //               className="text-white p-2 w-full rounded-lg md:h-16 text-3xl"
    //               style={{ background: "#393285" }}
    //             >
    //               LogIn
    //             </button>
    //           </form> */}
    //             <form
    //               onSubmit={otpSent ? handleVerifyOtp : handleSendOtp}
    //               className="space-y-4"
    //             >
    //               <div className="flex space-x-2">
    //                 <input
    //                   type="text"
    //                   value={countryCode}
    //                   onChange={(e) => setCountryCode(e.target.value)}
    //                   placeholder="+91"
    //                   className="border p-2 rounded-lg w-16 font-semibold md:h-14 text-2xl"
    //                 />
    //                 <input
    //                   type="text"
    //                   value={phoneNumber}
    //                   onChange={(e) => setPhoneNumber(e.target.value)}
    //                   placeholder="Mobile number"
    //                   className="border p-2 rounded-lg w-full font-semibold md:h-14 text-2xl"
    //                 />
    //               </div>
    //               {otpSent && (
    //                 <input
    //                   type="text"
    //                   value={otp}
    //                   onChange={(e) => setOtp(e.target.value)}
    //                   placeholder="Enter OTP"
    //                   className="border p-2 rounded-lg w-full font-semibold md:h-14 text-2xl"
    //                 />
    //               )}
    //               <button
    //                 type="submit"
    //                 className="text-white p-2 w-full rounded-lg md:h-16 text-3xl"
    //                 style={{ background: "#393285" }}
    //               >
    //                 {otpSent ? "Verify OTP" : "Send OTP"}
    //               </button>
    //             </form>
    //             <p className="text-center mt-4 font-semibold md:text-xl">
    //               Click here to login using Google/FB account
    //             </p>
    //             <div className="flex justify-center space-x-2 mt-2">
    //               <img
    //                 src="/google.png"
    //                 alt="Google"
    //                 className="w-8"
    //                 onClick={handleGoogleSignIn}
    //               />
    //               <img
    //                 src="/facebook.png"
    //                 alt="Facebook"
    //                 className="w-8"
    //                 onClick={handleFacebookSignIn}
    //               />
    //             </div>
    //           </div>
    //         </div>
    //       </div>

    //       {/* for mobile responsive duplicate added where employeer login section is hidden and it will display  */}
    //       <div className="text-xl hidden sm:hidden font-semibold ">
    //         <p>Looking for a Job?</p>
    //         <button
    //           className="text-blue-700"
    //           onClick={() => navigate("/Frame-11")}
    //         >
    //           Click Here
    //         </button>
    //       </div>

    //       {/* Companies that hire from us */}
    //       <div className="text-center mb-6 hidden md:block">
    //         <h2 className="text-2xl md:text-3xl font-bold text-purple-800">
    //           Companies that hire from us
    //         </h2>
    //       </div>

    //       <div className="hidden md:grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-10 items-center justify-center">
    //         {companies.map((logo, idx) => (
    //           <div
    //             key={idx}
    //             className="w-24 h-24 md:w-32 md:h-32 flex justify-center items-center"
    //           >
    //             <img
    //               src={`/companies logo/${logo}`}
    //               alt={logo}
    //               className="object-contain w-full h-full"
    //             />
    //           </div>
    //         ))}
    //       </div>
    //     </div>

    //     <div className="md:hidden">
    //       <HorizontalScroll data="Companies that hire from us" />
    //     </div>
    //   </div>
    // </div>
    <section className="py-24 w-full  font-inter">
      {/* ------------ container ---------------- */}

      <div className="xl:w-10/12 w-11/12 mx-auto  flex flex-col gap-y-4">
        {/* -------- 1st section -------- */}

        <div className="flex xmd:flex-row flex-col  xl:gap-x-8 gap-x-6 gap-y-24 justify-between ">
          {/* ----- left part ---- */}

          <div className="xl:w-[46%] xmd:w-[43%] md:w-[80%] sm:w-[90%] mx-auto flex flex-col gap-y-4 pr-6 xs:pr-0">
            <h1 className="xl:text-6xl lg:text-5xl text-4xl font-semibold xl:w-[80%] w-[90%] xl:leading-[77px] lg:leading-[62px]  leading-[56px] text-blue-200 ">
              Powering Your Hiring Success!!
            </h1>
            <p className="text-xl font-semibold ">
              Log in now and experience the future of hiring with Hirers.
            </p>
          </div>

          {/* ------- right part ----- */}
          <div className="xl:w-[45%] xmd:w-[50%] md:w-[80%] sm:w-[90%] w-full  mx-auto  bg-light-purple-100 sm:py-8 py-6 xl:px-10 sm:px-6 xs:px-4 px-2  rounded-[32px] flex flex-col gap-y-6 items-center ">
            <h2 className="xl:text-3xl xs:text-2xl text-xl font-semibold text-center">
              Employer LogIn/SignUp
            </h2>

            <form
              onSubmit={otpSent ? handleVerifyOtp : handleSendOtp}
              className="flex flex-col gap-y-6"
            >
              <div className="flex xl:gap-x-8 xsm:gap-x-4 gap-x-2 justify-between items-center xl:text-xl sm:text-lg text-base">
                <input
                  type="text"
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                  placeholder="+91"
                  className="py-3 px-2 sm:w-[60px] xsm:w-[48px] w-[46px] rounded-lg text-black placeholder-black font-medium"
                />
                <input
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Mobile Number"
                  className="py-3 px-4 rounded-lg placeholder-black text-black font-medium"
                />
              </div>
              {otpSent && (
                <input
                  type="text"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder="Enter OTP"
                  className="py-3 px-4 rounded-lg placeholder-black text-black font-medium"
                />
              )}

              <button
                type="submit"
                className="bg-blue-200 text-white flex items-center justify-center py-2 rounded-lg xl:text-2xl xsm:text-xl text-lg transition-all duration-300 hover:bg-blue-300 hover:scale-105 px-4"
              >
                {otpSent ? "Verify OTP" : "Login"}
              </button>
            </form>

            <div className="flex flex-col gap-y-3 items-center">
              <p className="sm:text-xl text-base font-medium text-center">
                Click here to login using Google/FB account
              </p>
              <div className="flex gap-x-4">
                <LazyLoadImage
                  src="/google.png"
                  alt="Google"
                    effect="blur"
                  className="sm:w-8 sm:h-8 w-7 h-7 cursor-pointer transition-all duration-300  hover:scale-110"
                  onClick={handleGoogleSignIn}
                />

                <LazyLoadImage
                  src="/facebook.png"
                  alt="facebook"
                    effect="blur"
                  className="sm:w-8 sm:h-8 w-7 h-7  cursor-pointer transition-all duration-300  hover:scale-110"
                  onClick={handleFacebookSignIn}
                />
              </div>
            </div>
          </div>
        </div>

        {/* ------- 2nd section ------ */}

        <div className=" font-semibold text-lg pt-6 xmd:pb-16 pb-4 ">
          <p className="text-black">Looking for a Job?</p>
          <button
            className="text-blue-200 transition-all duration-300 hover:text-blue-300 hover:scale-105"
            onClick={() => navigate("/Frame-11")}
          >
            Click Here
          </button>
        </div>

        {/* ------- 3rd section --- */}

        <div className=" py-2">
          <div className="hidden xmd:flex flex-col gap-y-12">
            <h2 className="text-center font-semibold text-blue-200 text-3xl">
              Companies that hire from us
            </h2>

            <div className="grid xl:grid-cols-7 lg:grid-cols-6 grid-cols-5 justify-items-center  gap-x-4 gap-y-12">
              {companies.map((logo, index) => (
                <div key={index} className="w-32 h-32">
                  <LazyLoadImage
                    src={`/companies logo/${logo}`}
                    alt="company-logo"
                    effect="blur"
                    className="w-full h-full object-contain rounded-full"
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="block xmd:hidden">
            <HorizontalScroll data="Company that hire from us" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Desktop3;
