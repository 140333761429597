import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa6";
import Swal from "sweetalert2";
// import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Payment = () => {
  const navigate = useNavigate();
  const [packageType, setPackageType] = useState("demand");
  const [total, setTotal] = useState(0);
  const basicAmount = 483;
  const demandAmount = 689;
  const gst = 189;
  const location = useLocation();
  const formDataToSend = location.state?.formDataToSend || {}; // Fallback to avoid errors
  const formData = new FormData();

  // Object.entries(formDataToSend).forEach(([key, value]) => {
  //   if (Array.isArray(value)) {
  //     value.forEach((item) => formData.append(key, item));
  //   } else {
  //     formData.append(key, value);
  //   }
  // });
  Object.entries(formDataToSend).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key, item);
      });
    } else {
      formData.append(key, value);
    }
  });

  // Log the constructed FormData
  // Append the orderId
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (packageType === "basic") {
      setTotal(basicAmount + gst);
    } else if (packageType === "demand") {
      setTotal(demandAmount + gst);
    }
  }, [packageType]);

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const handlePayment = async () => {
    const isScriptLoaded = await loadRazorpayScript();

    if (!isScriptLoaded) {
      Swal.fire("Error", "Failed to load Razorpay script.", "error");
      return;
    }

    try {
      // Fetch order details
      const orderResponse = await fetch(
        `${process.env.REACT_APP_PORT}/create-order`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ amount: total, currency: "INR" }),
        }
      );

      const orderData = await orderResponse.json();
      formData.append("orderId", orderData.id);
      formData.append("amount", orderData.amount);
      // Send job data

      await axios.post(`${process.env.REACT_APP_PORT}/api/postjob`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      const options = {
        key: "rzp_test_6udxVpYAJx9ZNh", // Replace with your Razorpay key_id
        amount: orderData.amount,
        currency: orderData.currency,
        name: formDataToSend.name,
        description: formDataToSend.jobDescription,
        order_id: orderData.id,
        handler: async (response) => {
          try {
            const verifyResponse = await fetch(
              `${process.env.REACT_APP_PORT}/verify-payment`,
              {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  order_id: orderData.id,
                  payment_id: response.razorpay_payment_id,
                  signature: response.razorpay_signature,
                }),
              }
            );

            const verifyData = await verifyResponse.json();

            if (verifyData.success) {
              // Swal.fire("Success", "Payment successful!", "success");
              Swal.fire({
                title: "Success",
                text: "Your payment was successful! Thanks for your submission. Your job post will be live after verification within 24 hours. Click here to make another post.",
                icon: "success",
                confirmButtonText: "Make another post",
              }).then((result) => {
                if (result.isConfirmed) {
                  // handleSwalResult(result);
                  navigate("/Desktop-4");
                } else {
                  navigate("/Frame-18");
                }
              });
            } else {
              // Swal.fire("Error", "Payment verification failed.", "error");
              Swal.fire({
                title: "Error",
                text: "Payment verification failed.",
                icon: "error",
                confirmButtonText: "Make another post",
              }).then((result) => {
                if (result.isConfirmed) {
                  // handleSwalResult(result);
                  navigate("/Desktop-4");
                } else {
                  navigate("/Frame-18");
                }
              });
            }
          } catch (verifyError) {
            console.error("Payment verification failed", verifyError);
            Swal.fire("Error", "Payment verification failed.", "error");
          }
        },
        prefill: {
          name: formDataToSend.name,
          email: formDataToSend.email,
          contact: formDataToSend.mobileNumber,
        },
        theme: {
          color: "#3399cc",
        },
      };

      const razorpayInstance = new window.Razorpay(options);
      razorpayInstance.open();
    } catch (error) {
      console.error("Error:", error);
      Swal.fire("Error", "Something went wrong.", "error");
    }
  };

  return (
    <section className="py-12 font-inter">
      {/* ------- container ---------- */}

      <div className="w-11/12 xmd:w-10/12 xlg:w-9/12 xl:w-8/12 xxl:w-[60%] mx-auto  flex flex-col gap-y-8">
        {/* ---- upper ---- */}

        <div className="flex flex-col gap-y-10">
          <h1 className="text-center text-blue-200 xmd:text-4xl sm:text-3xl text-2xl font-bold">
            Your Hiring Success Starts Here!
          </h1>

          <div className="flex flex-col gap-y-8 md:flex-row items-center  md:justify-between">
            {/* ----- basic box ----- */}

            <div
              className={`xlg:w-[400px] smd:w-[350px] md:w-[320px] xsm:w-[350px] w-[320px] xlg:h-[320px] h-[300px] bg-light-purple-100 xmd:px-12 px-6 py-6 flex flex-col gap-y-4 rounded-[30px] border-2 
                        ${
                          packageType === "basic"
                            ? "border-blue-200"
                            : "border-light-purple-100"
                        }
                            `}
            >
              <div className="w-full justify-center text-center flex flex-col gap-y-2">
                <h2 className="text-blue-200 md:text-2xl  text-xl font-semibold">
                  Basic
                </h2>
                <p className="flex gap-x-4 justify-center md:text-xl text-lg font-semibold">
                  <span>Rs.483</span>
                  <span className="line-through text-light-black-100">
                    Rs.549
                  </span>
                </p>
              </div>

              <div className="flex flex-col gap-y-2 text-base  font-normal">
                <p className="flex gap-x-2 items-center">
                  <span className="text-blue-200">
                    <FaCheck />
                  </span>
                  Unlimited Candidate Responses
                </p>
                <p className="flex gap-x-2 items-center">
                  <span className="text-blue-200">
                    <FaCheck />
                  </span>
                  Valid for 15 days
                </p>
                <p className="flex gap-x-2 items-center">
                  <span className="text-blue-200">
                    <FaCheck />
                  </span>
                  Keep your job active
                </p>
              </div>

              <div className="flex justify-center mt-3">
                <button
                  onClick={() => setPackageType("basic")}
                  className="py-3 px-8 bg-blue-200 text-white rounded-2xl text-base font-normal transition-all duration-300 hover:scale-105 hover:bg-blue-300"
                >
                  Select {packageType}
                </button>
              </div>
            </div>

            {/* ----- in demand box ----- */}

            <div
              className={`xlg:w-[400px] smd:w-[350px] md:w-[320px] xsm:w-[350px] w-[320px] xlg:h-[320px] h-[300px] bg-light-purple-100 xmd:px-12 px-6 py-6 flex flex-col gap-y-4 rounded-[30px] border-2 
                        ${
                          packageType === "demand"
                            ? "border-blue-200"
                            : "border-light-purple-100"
                        }
                            `}
            >
              <div className="w-full justify-center text-center flex flex-col gap-y-2">
                <h2 className="text-blue-200 md:text-2xl  text-xl   font-semibold">
                  In Demand
                </h2>
                <p className=" md:text-xl text-lg font-semibold">Rs.689</p>
              </div>

              <div className="flex flex-col gap-y-2 text-base  font-normal">
                <p className="flex gap-x-2 items-center">
                  <span className="text-blue-200">
                    <FaCheck />
                  </span>
                  Unlimited Candidate Responses
                </p>
                <p className="flex gap-x-2 items-center">
                  <span className="text-blue-200">
                    <FaCheck />
                  </span>
                  Valid for 21 days
                </p>
                <p className="flex gap-x-2 items-center">
                  <span className="text-blue-200">
                    <FaCheck />
                  </span>
                  Keep your job active
                </p>
              </div>

              <div className="flex justify-center mt-3">
                <button
                  onClick={() => setPackageType("demand")}
                  className="py-3 px-8 bg-blue-200 text-white rounded-2xl text-base font-normal transition-all duration-300 hover:scale-105 hover:bg-blue-300"
                >
                  Select
                </button>
              </div>
            </div>
          </div>

          <p>*All prices are excluding taxes</p>
        </div>

        {/* ---- checkout ----- */}

        {packageType && (
          <div className=" flex flex-col gap-y-3 sm:text-lg xmd:text-base text-sm">
            <h2 className="font-bold">Checkout</h2>
            <div className="flex flex-col gap-y-1">
              <div className="flex flex-row justify-between">
                <p>{packageType === "basic" ? "Basic" : "In Demand"}</p>
                <p>
                  {packageType === "basic"
                    ? `Rs ${basicAmount}`
                    : `Rs ${demandAmount}`}
                </p>
              </div>

              <div className="flex flex-row justify-between">
                <p>
                  GST(18%) <span className="text-blue-200">Add GSTIN</span>
                </p>
                <p>Rs {gst}</p>
              </div>
            </div>
            <p className="font-bold flex justify-between">
              <p>
                Total <span className="font-normal text-sm">(Inc tax)</span>
              </p>
              <p>Rs {total}</p>
            </p>

            <div className="flex justify-end mt-3">
              <button
                className="py-2 px-8 bg-blue-200 text-white rounded-2xl text-base font-normal transition-all duration-300 hover:scale-105 hover:bg-blue-300"
                onClick={handlePayment}
              >
                Pay Rs {total}
              </button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Payment;
