import React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { GiHamburgerMenu } from "react-icons/gi";

const Navbar1 = () => {
  const navigate = useNavigate();
  // const token = localStorage.getItem("token");

  // const handleProf = () => {
  //   navigate("/Section-7");
  // };
  const frame18 = () => {
    navigate("/Desktop-4");
  };
  const handleDashboard = () => {
    navigate("/Frame-18");
  };
  const handleDashboard1 = () => {
    navigate("/");
  };
  const handleProfile = () => {
    navigate("/Section-7");
  };

  return (
    <div className="w-full xs:h-18 h-16 text-white flex justify-between items-center mx-auto bg-primary-blue font-inter ">
      <div className=" h-14 w-14 rounded-full flex items-center cursor-pointer shadow-xl">
        <img src="/hirersLogo.png" alt="logo" onClick={handleDashboard1} />
      </div>
      <div className="flex items-center justify-evenly">
        <span
          className="block md:hidden md:h-10 md:pr-3 pt-2 cursor-pointer mr-7  text-lg"
          onClick={handleDashboard}
        >
          Dashboard
        </span>
        <span
          className="block md:hidden md:h-10 md:pr-3 pt-2 cursor-pointer mr-7  text-lg"
          onClick={frame18}
        >
          Post Job
        </span>
        <span className="md:hidden flex md:pr-3 pt-1">
          <div
            className=" xs:h-12 xs:w-12 h-10 w-10  mr-4 rounded-full"
            onClick={handleProfile}
          >
            <img
              src="/profile-img.webp" // Corrected path to the image in the public folder
              alt="menu icon"
              className="h-full w-full rounded-full"
            />
          </div>
        </span>
        {/* Post Job */}
        <span
          className="hidden md:block pr-3 pt-1 cursor-pointer mr-24 text-2xl"
          onClick={handleDashboard}
        >
          Dashboard
        </span>
        {/* Post Job */}
        <span
          className="hidden md:block pr-3 pt-1 cursor-pointer mr-24 text-2xl"
          onClick={frame18}
        >
          Post Job
        </span>
        {/* Your Profile */}
        <div className="relative hidden md:flex items-center bg-white rounded-xl px-5 py-1 mt-2 mr-8 cursor-pointer">
          <p
            className="cursor-pointer text-primary-blue text-2xl"
            onClick={handleProfile}
          >
            Your Profile
          </p>

          <div className=" h-8 w-8 ml-4  ">
            <GiHamburgerMenu className="text-black h-full w-full" />
          </div>
        </div>
        {/* <img
          src="/profile-img.webp"
          alt="profile icon"
          className=" hidden md:flex mx-3 cursor-pointer w-10 h-10 rounded-xl mt-2  mr-4"

          onClick={handleProfile}
        /> */}
        <div className="sm:mr-4">
          <span
            className="material-symbols-outlined "
            style={{
              color: "#4285F4",
              cursor: "pointer",
              fontSize: "50px",
              float: "right",
            }}
            onClick={() => {
              Swal.fire({
                title: "Are you sure you want to log out?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, log out",
                cancelButtonText: "Cancel",
              }).then((result) => {
                if (result.isConfirmed) {
                  // Clear local storage items
                  localStorage.removeItem("type");
                  localStorage.removeItem("token");

                  // Show success message
                  Swal.fire({
                    title: "Logged out successfully",
                    icon: "success",
                    timer: 2000,
                    showConfirmButton: false,
                  });

                  // Redirect to home page
                  navigate("/");
                }
              });
            }}
          >
            logout
          </span>
        </div>
      </div>
    </div>
  );
};

export default Navbar1;
