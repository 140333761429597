import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Cards = () => {

  //Mushafiya -> Remove border and use small size for text and change some padding 
  //Add lazy loading to images 


  return (
    <div className="bg-white  py-14 px-10 font-inter">
      {/* Header */}
      <div className="flex justify-center items-center w-full">
        <h1 className="text-4xl text-primary-blue   text-purple-700 font-bold text-center ">
          How to Start with Hirers!
        </h1>
      </div>

      {/* Cards Container */}
      <div className="flex flex-wrap justify-center items-center mt-16 gap-20">
        {/* Card 1 */}
        <div className=" flex flex-col h-[380px] w-[300px] md:h-[420px] md:w-[320px] bg-light-purple-100 shadow-lg px-5 rounded-2xl transition-transform duration-300 hover:shadow-2xl transform hover:scale-105">
          <div className="flex justify-center items-center h-1/3">
            <LazyLoadImage
              effect="blur"
              src="/jobsearch.png"
              className="w-[60%]  mx-auto h-auto object-cover rounded-full"
              alt="jobsearch"
            />
          </div>

          <div className="text-center mt-2">
            <p className="text-xl md:text-2xl mb-2 text-secondary-blue font-bold text-purple-600 transition duration-300 hover:text-purple-800">
              Post/Search a Job
            </p>
          </div>
          <div className="">
            <span className="text-lg font-bold ">For Job Seekers:</span>
            <p className="text-base  mt-1 mb-3">
              Look around the verified listing jobs at our platform.
            </p>
          </div>
          <div>
            <span className="text-lg font-bold ">For Employers:</span>
            <p className="text-base  mt-1">
              Fill your required details for candidates in 2-3 minutes.
            </p>
          </div>
        </div>

        {/* Card 2 */}
        <div className="flex flex-col h-[380px] w-[300px] md:h-[420px] md:w-[320px] bg-light-purple-100 shadow-lg px-5 rounded-2xl transition-transform duration-300 hover:shadow-2xl transform hover:scale-105">
          <div className="flex justify-center items-center h-1/3">
            <LazyLoadImage
              effect="blur"
              src="/verified.png"
              className="w-[50%] h-auto object-cover mx-auto rounded-full"
              alt="verified"
            />
          </div>
          <div className="text-center mt-2">
            <p className="text-xl md:text-2xl mb-2 text-secondary-blue font-bold text-purple-600 transition duration-300 hover:text-purple-800">
              Apply to be Verified
            </p>
          </div>
          <div>
            <span className="text-lg  font-bold ">For Job Seekers:</span>
            <p className="text-base  mt-1 mb-2">
              Apply to job vacancies available there by just doing Sign-up.
            </p>
          </div>
          <div>
            <span className="text-lg  font-bold ">For Employers:</span>
            <p className="text-base   mt-1">
              Our team will call to verify your details.
            </p>
          </div>
        </div>

        {/* Card 3 */}
        <div className=" flex flex-col h-[380px] w-[300px] md:h-[420px] md:w-[320px] bg-light-purple-100 shadow-lg px-5 rounded-2xl transition-transform duration-300 hover:shadow-2xl transform hover:scale-105">
          <div className="flex justify-center items-center h-1/3">
            <LazyLoadImage
              effect="blur"
              src="/result.png"
              className="w-[80%] h-auto object-cover mx-auto  rounded-full"
              alt="result"
            />
          </div>

          <div className="text-center mt-1">
            <p className="text-xl md:text-2xl mb-3 text-secondary-blue font-bold text-purple-600 transition duration-300 hover:text-purple-800">
              Get Your Results
            </p>
          </div>
          <div className="">
            <span className="text-lg  font-bold ">For Job Seekers:</span>
            <p className="text-base mt-1 mb-2">Get connect to HR’s directly.</p>
          </div>
          <div>
            <span className="text-lg  font-bold ">For Employers:</span>
            <p className="text-base   mt-1">
              Get calls from relevant candidates or call them directly from our
              candidate database.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
