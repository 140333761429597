import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { motion } from "framer-motion";

function AutoPlayMethods({ data }) {
  // Array of image logos
  const companyLogos = [
    "companies logo/ambar skytech.png",
    "companies logo/airtel.jpg",
    "companies logo/daikin.png",
    "companies logo/paytm.png",
    "companies logo/policybazaar.jpeg",
    "companies logo/rapido.png",
    "companies logo/samsung.png",
    "companies logo/swiggy.png",
    "companies logo/techmahindra.png",
    "companies logo/tp.png",
    "companies logo/webvi.png",
    "companies logo/Zomato_logo.png",
    "companies logo/uber.png",
    "companies logo/moneylink.png",
    "companies logo/ambar skytech.png",
    "companies logo/airtel.jpg",
    "companies logo/daikin.png",
    "companies logo/paytm.png",
    "companies logo/policybazaar.jpeg",
    "companies logo/rapido.png",
    "companies logo/samsung.png",
    "companies logo/swiggy.png",
    "companies logo/techmahindra.png",
    "companies logo/tp.png",
    "companies logo/webvi.png",
    "companies logo/Zomato_logo.png",
    "companies logo/uber.png",
    "companies logo/moneylink.png",
    "companies logo/ambar skytech.png",
    "companies logo/airtel.jpg",
    "companies logo/daikin.png",
    "companies logo/paytm.png",
    "companies logo/policybazaar.jpeg",
    "companies logo/rapido.png",
    "companies logo/samsung.png",
    "companies logo/swiggy.png",
    "companies logo/techmahindra.png",
    "companies logo/tp.png",
    "companies logo/webvi.png",
    "companies logo/Zomato_logo.png",
    "companies logo/uber.png",
    "companies logo/moneylink.png",
  ];

  return (
    <section className="py-8 sm:py-12 font-inter ">
      {/* -------- container -------- */}

      <div className="xl:w-10/12 w-11/12 mx-auto  flex flex-col md:gap-y-12 gap-y-8">
        <h2 className="text-center md:text-4xl sm:text-3xl text-2xl font-semibold text-blue-200">
          {data}
        </h2>
      </div>

      {/* -------- company logo ------- */}

      <div className=" overflow-hidden w-11/12 mx-auto xmd:mt-10 mt-8 ">
        <motion.div
          animate={{ x: ["0", "-350%"] }}
          transition={{
            x: {
              repeat: Infinity,
              repeatType: "loop",
              duration: 18, // Adjust duration for the scroll speed
              ease: "linear",
            },
          }}
          className="   relative flex gap-x-12"
        >
          {companyLogos.map((logo, index) => (
            <div className="lg:w-48 lg:h-48 md:w-40 md:h-40 sm:w-36 sm:h-36 xs:w-32 xs:h-32 w-28 h-28 aspect-square ">
              <LazyLoadImage
                className="w-full h-full object-contain rounded-full"
                src={process.env.PUBLIC_URL + "/" + logo}
                alt={`Company logo ${index + 1}`}
                effect="blur"
              />
            </div>
          ))}
        </motion.div>
      </div>
    </section>
  );
}

export default AutoPlayMethods;
