import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

const Desktop4 = () => {
  const navigation = useNavigate();
  const token = localStorage.getItem("token");

  const {
    register,
    handleSubmit,
    formState: { errors },
    // eslint-disable-next-line no-unused-vars
    reset,
    watch,
    setValue,
  } = useForm();

  useEffect(() => {
    const handleErrors = async () => {
      if (!token) {
        toast.dismiss();
        toast.error("Session Expired! Please login again");
        navigation("/");
        return;
      }

      try {
        await axios.post(
          `${process.env.REACT_APP_PORT}/api/protected`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        console.error("Token verification failed:", error);
        toast.error("Session Expired! Please login again");
        navigation("/");
        return;
      }
    };

    handleErrors();
  }, [token, navigation]);

  const handleSubmitForm = async (data, e) => {
    e.preventDefault();
    // loading = true;
    // handleProgress();

    const formattedData = {
      organizationName: data.organizationName,
      name: data.name,
      designation: data.designation,
      mobileNumber: data.mobileNumber,
      email: data.email,
      organizationAddress: data.organizationAddress,
      companyState: data.companyState,
      companyCity: data.companyCity,
      companyPincode: data.companyPincode,
      companyName: data.companyName,
      jobTitle: data.jobTitle,
      jobLocation: data.jobLocation,
      jobState: data.jobState,
      jobCity: data.jobCity,
      jobPincode: data.jobPincode,

      interviewLocation: data.interviewLocation,
      interviewState: data.interviewState,
      interviewCity: data.interviewCity,
      interviewPincode: data.interviewPincode,
      interviewTiming: data.interviewTiming,
      jobDescription: data.jobDescription,
      educationQualification: Object.keys(data.educationQualification || {})
        .filter((key) => data.educationQualification[key])
        .map((key) => key.replace("_", " ")), // Remove underscores and format

      communicationSkill: Object.keys(data.communicationSkill || {})
        .filter((key) => data.communicationSkill[key])
        .map((key) => key.replace("_", " ")),

      workType: Object.keys(data.workType || {})
        .filter((key) => data.workType[key])
        .map((key) => key.replace("_", " ")),

      workingHours: data.workingHours,
      salary: data.salary,

      experienceLevel: Object.keys(data.experienceLevel || {})
        .filter((key) => data.experienceLevel[key])
        .map((key) => key.replace("_", " ")),

      interviewProcess: Object.keys(data.interviewProcess || {})
        .filter((key) => data.interviewProcess[key])
        .map((key) => key.replace("_", " ")),
    };

    e.preventDefault();
    const formDataToSend = {};
    // formDataToSend.append("orderId", orderId);
    Object.entries(formattedData).forEach(([key, value]) => {
      if (value !== null) {
        formDataToSend[key] = value; // Populate plain object
      }
    });
    try {
      navigation("/pricing_page", { state: { formDataToSend } });
    } catch (e) {
      console.log("error", e);
      navigation("/");
    }
  };

  const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Delhi",
    "Jammu and Kashmir",
    "Ladakh",
    "Lakshadweep",
    "Puducherry",
  ];

  const educations = [
    {
      id: 1,
      title: "10th Passed",
      value: "10thPassed",
    },
    {
      id: 2,
      title: "12th Passed",
      value: "12thPassed",
    },
    {
      id: 3,
      title: "Graduated and above",
      value: "graduateAbove",
    },
  ];

  const workType = [
    {
      id: 1,
      title: "Hybrid",
      value: "hybrid",
    },
    {
      id: 2,
      title: "Work from Home",
      value: "home",
    },
    {
      id: 3,
      title: "Work from Office",
      value: "office",
    },
  ];

  const communicationSkills = [
    {
      id: 1,
      title: "Hindi",
      value: "hindi",
    },
    {
      id: 2,
      title: "English (Normal Speaking)",
      value: "englishNormal",
    },
    {
      id: 3,
      title: "English (Intermediate)",
      value: "englishIntermediate",
    },
    {
      id: 4,
      title: "English (Fluent)",
      value: "englishFluent",
    },
    {
      id: 5,
      title: "Others",
      value: "other",
    },
  ];

  const experienceLevel = [
    {
      id: 1,
      title: "Fresher",
      value: "fresher",
    },
    {
      id: 2,
      title: "Experience (0 - 1 yr)",
      value: "0-1yr",
    },
    {
      id: 3,
      title: "Experience (1 - 2 yr)",
      value: "1-2yr",
    },
    {
      id: 4,
      title: "Experience (more than 2 yr)",
      value: "moreThan2yr",
    },
  ];

  const interviewProcess = [
    {
      id: 1,
      title: "Telephonic",
      value: "telephonic",
    },
    {
      id: 2,
      title: "Virtual (online mode)",
      value: "virtual",
    },
    {
      id: 3,
      title: "Offline (In person)",
      value: "offline",
    },
  ];

  // State to track checkboxes for disabling fields
  const [isJobSameAsCompany, setIsJobSameAsCompany] = useState(false);
  const [isInterviewSameAsJob, setIsInterviewSameAsJob] = useState(false);

  const companyState = watch("companyState");
  const companyCity = watch("companyCity");
  const companyPincode = watch("companyPincode");
  const jobLocation = watch("jobLocation");
  const jobState = watch("jobState");
  const jobCity = watch("jobCity");
  const jobPincode = watch("jobPincode");

  // Handle the first checkbox: Sync Job Location with Company Location

  const handleCompanyToJobSync = (e) => {
    const isChecked = e.target.checked;
    setIsJobSameAsCompany(isChecked);

    if (isChecked) {
      // Set Job Location values to match Company Location
      setValue("jobState", companyState);
      setValue("jobCity", companyCity);
      setValue("jobPincode", companyPincode);

      setIsJobSameAsCompany(true);
    } else {
      // Clear Job Location values when unchecked
      setValue("jobState", "");
      setValue("jobCity", "");
      setValue("jobPincode", "");
    }
  };

  const handleJobToInterviewSync = (e) => {
    const isChecked = e.target.value;

    if (isChecked) {
      setValue("interviewLocation", jobLocation);
      setValue("interviewState", jobState);
      setValue("interviewCity", jobCity);
      setValue("interviewPincode", jobPincode);

      setIsInterviewSameAsJob(true);
    } else {
      // Clear Interview Location values when unchecked
      setValue("interviewLocation", "");
      setValue("interviewState", "");
      setValue("interviewCity", "");
      setValue("interviewPincode", "");
    }
  };

  return (
    <div className="w-full h-full bg-no-repeat pt-12 xmd:pt-0  bg-right font-inter sm:bg-gray bg-white">
      {/* ---------- upper header part ---------- */}
      <section className=" xmd:flex hidden pt-3  bg-blue-200 text-white rounded-b-3xl lg:text-3xl text-2xl font-semibold  tracking-wider">
        <div className="w-11/12 mx-auto flex flex-col xl:gap-y-1 lg:gap-y-3 items-center">
          <h2 className=" text-center ">“POST A JOB IN 3 EASY STEPS”</h2>

          <div className="grid grid-cols-5 justify-items-center items-center xl:text-2xl  lg:text-xl text-lg">
            <p className="text-center ">1) Submit Job Details</p>

            <div className="xl:h-28 xl:w-28 w-24 h-24 ">
              <img
                src="/Arrow1.png"
                className="h-full w-full object-contain rotate-[330deg]"
                alt="arrow"
              />
            </div>

            <p className="text-center ">2) Select Payment Method</p>

            <div className="xl:h-28 xl:w-28 w-24 h-24 ">
              <img
                src="/Arrow1.png"
                className="h-full w-full object-contain rotate-[330deg]"
                alt="arrow"
              />
            </div>

            <p className="text-center ">
              3) Job will be Posted after Verification
            </p>
          </div>
        </div>
      </section>

      <h1 className="flex justify-center xmd:hidden md:text-4xl sm:text-3xl text-2xl text-blue-200   font-bold text-center ">
        Post a Job in 3 Easy Steps
      </h1>

      {/* ------ bottom form part ---------- */}

      <section
        // style={{
        //   backgroundImage: `url(${process.env.PUBLIC_URL}/jobpost.png)`,
        // }}
        className=" sm:pt-12 pt-6 pb-12 bg-no-repeat sm:bg-[320px] bg-[1000px] bg-contain"
      >
        <div className="w-11/12 mx-auto flex flex-col gap-y-8">
          <h2 className="text-3xl font-semibold text-blue-400 xmd:block hidden">
            Fill Job Details
          </h2>
          {/*  */}
          {/* -------- form part -------- */}

          <form
            onSubmit={handleSubmit(handleSubmitForm)}
            // onSubmit={handlePrice}
            className="xmd:w-[90%] w-[95%] mx-auto  justify-center  bg-white rounded-2xl py-8 xmd:px-8 xs:px-6 flex flex-col gap-y-6 lg:text-xl md:text-lg sm:text-base text-sm"
          >
            {/* --------- organization name & your name ----------- */}

            <div className="flex sm:flex-row flex-col gap-y-4  justify-between">
              <div className="xlg:w-[45%] xmd:w-[47%] sm:w-[48%] w-full flex flex-col gap-y-2">
                <input
                  type="text"
                  placeholder="Organization Name*"
                  className="w-full bg-light-purple-100 py-3 px-3 rounded-2xl outline-none focus:outline-blue-300 text-blue-200 placeholder-blue-200   font-medium"
                  {...register("organizationName", {
                    required: "Organization Name is required.",
                  })}
                />
                {errors.organizationName && (
                  <p className="text-warning-red px-2 text-sm">
                    {errors.organizationName.message}
                  </p>
                )}
              </div>

              <div className="xlg:w-[45%] xmd:w-[47%] sm:w-[48%] w-full  flex flex-col gap-y-2">
                <input
                  type="text"
                  placeholder="Your Name*"
                  className="w-full bg-light-purple-100 py-3 px-3 rounded-2xl outline-none focus:outline-blue-300 text-blue-200 placeholder-blue-200   font-medium"
                  {...register("name", { required: "Name is required." })}
                />
                {errors.name && (
                  <p className="text-warning-red px-2 text-sm">
                    {errors.name.message}
                  </p>
                )}
              </div>
            </div>

            {/* --------- Designation & contact number ----------- */}

            <div className="flex sm:flex-row flex-col gap-y-4  justify-between">
              <div className="xlg:w-[45%] xmd:w-[47%] sm:w-[48%] w-full flex flex-col gap-y-2">
                <input
                  type="text"
                  placeholder="Designation*"
                  className="w-full bg-light-purple-100 py-3 px-3 rounded-2xl outline-none focus:outline-blue-300 text-blue-200 placeholder-blue-200  font-medium"
                  {...register("designation", {
                    required: "Designation is required.",
                  })}
                />
                {errors.designation && (
                  <p className="text-warning-red text-sm px-2">
                    {errors.designation.message}
                  </p>
                )}
              </div>

              <div className="xlg:w-[45%] xmd:w-[47%] sm:w-[48%] w-full flex flex-col gap-y-2">
                <input
                  type="text"
                  placeholder="Mobile Number*"
                  className="w-full bg-light-purple-100 py-3 px-3 rounded-2xl outline-none focus:outline-blue-300 text-blue-200 placeholder-blue-200   font-medium"
                  {...register("mobileNumber", {
                    required: "Mobile number is required.",
                    pattern: {
                      value: /^[6-9][0-9]{9}$/, // Example: Validates Indian numbers
                      message: "Enter a valid mobile number.",
                    },
                  })}
                />
                {errors.mobileNumber && (
                  <p className="text-warning-red px-2 text-sm">
                    {errors.mobileNumber.message}
                  </p>
                )}
              </div>
            </div>

            {/* --------- Email & Organization Address ----------- */}

            <div className="flex sm:flex-row flex-col gap-y-4  justify-between">
              <div className="xlg:w-[45%] xmd:w-[47%] sm:w-[48%] w-full flex flex-col gap-y-2">
                <input
                  type="email"
                  placeholder="Email (prefer work email)*"
                  className="w-full bg-light-purple-100 py-3 px-3 rounded-2xl outline-none focus:outline-blue-300 text-blue-200 placeholder-blue-200   font-medium"
                  {...register("email", {
                    required: "Email is required.",
                    pattern: {
                      value: /^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Invalid email.",
                    },
                  })}
                />
                {errors.email && (
                  <p className="text-warning-red text-sm px-2">
                    {errors.email.message}
                  </p>
                )}
              </div>

              <div className="xlg:w-[45%] xmd:w-[47%] sm:w-[48%] w-full flex flex-col gap-y-2">
                <input
                  type="text"
                  placeholder="Organization Address*"
                  className="w-full bg-light-purple-100 py-3 px-3 rounded-2xl outline-none focus:outline-blue-300 text-blue-200 placeholder-blue-200   font-medium"
                  {...register("organizationAddress", {
                    required: "Organization Address is required",
                  })}
                />
                {errors.organizationAddress && (
                  <p className="text-warning-red px-2 text-sm">
                    {errors.organizationAddress.message}
                  </p>
                )}
              </div>
            </div>

            {/* --------- State , City & Pincode ----------- */}

            <div className="grid sm:grid-cols-3 grid-cols-2 gap-y-4  justify-between">
              <div className="w-[90%] flex flex-col gap-y-2">
                <select
                  id="states"
                  {...register("companyState", {
                    required: "State is required",
                  })}
                  className="w-full bg-light-purple-100 py-3 px-3 rounded-2xl outline-none focus:outline-blue-300 text-blue-200 placeholder-blue-200   font-medium"
                >
                  <option value="" key={0}>
                    State*
                  </option>
                  {states.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </select>

                {errors.companyState && (
                  <p className="text-warning-red text-sm px-2">
                    {errors.companyState.message}
                  </p>
                )}
              </div>

              <div className="w-[90%] flex flex-col gap-y-2">
                <input
                  type="text"
                  placeholder="City*"
                  className="w-full bg-light-purple-100 py-3 px-3 rounded-2xl outline-none focus:outline-blue-300 text-blue-200 placeholder-blue-200   font-medium"
                  {...register("companyCity", { required: "City is required" })}
                />
                {errors.companyCity && (
                  <p className="text-warning-red px-2 text-sm">
                    {errors.companyCity.message}
                  </p>
                )}
              </div>

              <div className="w-[90%] flex flex-col gap-y-2">
                <input
                  type="text"
                  placeholder="Pincode*"
                  className="w-full bg-light-purple-100 py-3 px-3 rounded-2xl outline-none focus:outline-blue-300 text-blue-200 placeholder-blue-200   font-medium"
                  {...register("companyPincode", {
                    required: "Pincode is required",
                    pattern: {
                      value: /^[1-9][0-9]{5}$/, // Matches a valid 6-digit  pincode
                      message: "Enter a valid 6-digit pincode",
                    },
                  })}
                />
                {errors.companyPincode && (
                  <p className="text-warning-red px-2 text-sm">
                    {errors.companyPincode.message}
                  </p>
                )}
              </div>
            </div>

            {/* --------- Company name & Job Title ----------- */}

            <div className="flex sm:flex-row flex-col gap-y-4  justify-between">
              <div className="xlg:w-[45%] xmd:w-[47%] sm:w-[48%] w-full flex flex-col gap-y-2">
                <input
                  type="text"
                  placeholder="Company's name (you're hiring for)*"
                  className="w-full bg-light-purple-100 py-3 px-3 rounded-2xl outline-none focus:outline-blue-300 text-blue-200 placeholder-blue-200   font-medium"
                  {...register("companyName", {
                    required: "Company name is required",
                  })}
                />
                {errors.companyName && (
                  <p className="text-warning-red text-sm px-2">
                    {errors.companyName.message}
                  </p>
                )}
              </div>

              <div className="xlg:w-[45%] xmd:w-[47%] sm:w-[48%] w-full flex flex-col gap-y-2">
                <input
                  type="text"
                  placeholder="Job Title*"
                  className="w-full bg-light-purple-100 py-3 px-3 rounded-2xl outline-none focus:outline-blue-300 text-blue-200 placeholder-blue-200   font-medium"
                  {...register("jobTitle", {
                    required: "Job title is required",
                  })}
                />
                {errors.jobTitle && (
                  <p className="text-warning-red px-2 text-sm">
                    {errors.jobTitle.message}
                  </p>
                )}
              </div>
            </div>

            {/* ------- checking aboove address ------ */}

            <div className="text-sm flex gap-x-2 my-4">
              <input
                type="checkbox"
                className="bg-light-purple-100"
                onChange={handleCompanyToJobSync}
              />
              Same as above address
            </div>

            {/* --------- Job Location ----------- */}

            <div className="flex sm:flex-row flex-col gap-y-4  justify-between">
              <div className="xlg:w-[45%] xmd:w-[47%] sm:w-[48%] w-full flex flex-col gap-y-2">
                <input
                  type="text"
                  placeholder="Job Location*"
                  className="w-full bg-light-purple-100 py-3 px-3 rounded-2xl outline-none focus:outline-blue-300 text-blue-200 placeholder-blue-200   font-medium"
                  {...register("jobLocation", {
                    required: "Job Location is required",
                  })}
                />
                {errors.jobLocation && (
                  <p className="text-warning-red text-sm px-2">
                    {errors.jobLocation.message}
                  </p>
                )}
              </div>
            </div>

            {/* --------- State , City & Pincode ----------- */}

            <div className="grid sm:grid-cols-3 grid-cols-2 gap-y-4  justify-between">
              <div className="w-[90%] flex flex-col gap-y-2">
                <select
                  id="states"
                  {...register("jobState", { required: "State is required" })}
                  className="w-full bg-light-purple-100 py-3 px-3 rounded-2xl outline-none focus:outline-blue-300 text-blue-200 placeholder-blue-200   font-medium"
                  disabled={isJobSameAsCompany}
                >
                  <option value="" key={0}>
                    State*
                  </option>
                  {states.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </select>

                {errors.jobState && (
                  <p className="text-warning-red text-sm px-2">
                    {errors.jobState.message}
                  </p>
                )}
              </div>

              <div className="w-[90%] flex flex-col gap-y-2">
                <input
                  type="text"
                  placeholder="City*"
                  className="w-full bg-light-purple-100 py-3 px-3 rounded-2xl outline-none focus:outline-blue-300 text-blue-200 placeholder-blue-200   font-medium"
                  {...register("jobCity", { required: "City is required" })}
                  disabled={isJobSameAsCompany}
                />
                {errors.jobCity && (
                  <p className="text-warning-red px-2 text-sm">
                    {errors.jobCity.message}
                  </p>
                )}
              </div>

              <div className="w-[90%] flex flex-col gap-y-2">
                <input
                  type="text"
                  placeholder="Pincode*"
                  className="w-full bg-light-purple-100 py-3 px-3 rounded-2xl outline-none focus:outline-blue-300 text-blue-200 placeholder-blue-200   font-medium"
                  disabled={isJobSameAsCompany}
                  {...register("jobPincode", {
                    required: "Pincode is required",
                    pattern: {
                      value: /^[1-9][0-9]{5}$/, // Matches a valid 6-digit  pincode
                      message: "Enter a valid 6-digit pincode",
                    },
                  })}
                />
                {errors.jobPincode && (
                  <p className="text-warning-red px-2 text-sm">
                    {errors.jobPincode.message}
                  </p>
                )}
              </div>
            </div>

            {/* ----------- Qualification and communication skill ------ */}

            <div className="flex sm:flex-row flex-col gap-y-4  justify-between">
              <div className="xlg:w-[45%] xmd:w-[47%] sm:w-[48%] w-full flex flex-col gap-y-2">
                <div className="flex flex-col gap-y-3">
                  <p className="w-full bg-light-purple-100 py-3 px-3 rounded-2xl outline-none focus:outline-blue-300 text-blue-200 placeholder-blue-200   font-medium">
                    Education Qualification (Select all that apply)*:
                  </p>

                  {educations.map((item) => (
                    <label
                      key={item.id}
                      className="flex gap-x-2 lg:text-lg sm:text-base text-sm"
                    >
                      <input
                        type="checkbox"
                        {...register(`educationQualification.${item.value}`)}
                      />
                      {item.title}
                    </label>
                  ))}
                </div>
              </div>

              <div className="xlg:w-[45%] xmd:w-[47%] sm:w-[48%] w-full flex flex-col gap-y-2">
                <div className="flex flex-col gap-y-3">
                  <p className="w-full bg-light-purple-100 py-3 px-3 rounded-2xl outline-none focus:outline-blue-300 text-blue-200 placeholder-blue-200   font-medium">
                    Communication Skills (Select all that apply)*:
                  </p>
                  {communicationSkills.map((item) => (
                    <label
                      key={item.id}
                      className="flex gap-x-2 lg:text-lg sm:text-base text-sm"
                    >
                      <input
                        type="checkbox"
                        {...register(`communicationSkill.${item.value}`)}
                      />
                      {item.title}
                    </label>
                  ))}
                </div>
              </div>
            </div>

            {/* ----------- work type and working hours ------ */}

            <div className="flex sm:flex-row flex-col gap-y-4  justify-between">
              <div className="xlg:w-[45%] xmd:w-[47%] sm:w-[48%] w-full flex flex-col gap-y-2">
                <div className="flex flex-col gap-y-3">
                  <p className="w-full bg-light-purple-100 py-3 px-3 rounded-2xl outline-none focus:outline-blue-300 text-blue-200 placeholder-blue-200   font-medium">
                    Work Type (Select all that apply)*:
                  </p>
                  {workType.map((item) => (
                    <label
                      key={item.id}
                      className="flex gap-x-2 lg:text-lg sm:text-base text-sm"
                    >
                      <input
                        type="checkbox"
                        {...register(`workType.${item.value}`)}
                      />
                      {item.title}
                    </label>
                  ))}
                </div>
              </div>

              <div className="xlg:w-[45%] xmd:w-[47%] sm:w-[48%] w-full flex flex-col gap-y-2">
                <input
                  type="text"
                  placeholder="Working Hours (timings)*"
                  className="w-full bg-light-purple-100 py-3 px-3 rounded-2xl outline-none focus:outline-blue-300 text-blue-200 placeholder-blue-200   font-medium"
                  {...register("workingHours")}
                />
              </div>
            </div>

            {/* --------- Salary ----------- */}

            <div className="flex sm:flex-row flex-col gap-y-4  justify-between">
              <div className="xlg:w-[45%] xmd:w-[47%] sm:w-[48%] w-full flex flex-col gap-y-2">
                <input
                  type="text"
                  placeholder="Salary*"
                  className="w-full bg-light-purple-100 py-3 px-3 rounded-2xl outline-none focus:outline-blue-300 text-blue-200 placeholder-blue-200   font-medium"
                  {...register("salary", { required: "Salary is required" })}
                />
                {errors.salary && (
                  <p className="text-warning-red text-sm px-2">
                    {errors.salary.message}
                  </p>
                )}
              </div>
            </div>

            {/* ----------- Exp level & Interview process ------ */}

            <div className="flex sm:flex-row flex-col gap-y-4  justify-between">
              <div className="xlg:w-[45%] xmd:w-[47%] sm:w-[48%] w-full flex flex-col gap-y-2">
                <div className="flex flex-col gap-y-3">
                  <p className="w-full bg-light-purple-100 py-3 px-3 rounded-2xl outline-none focus:outline-blue-300 text-blue-200 placeholder-blue-200   font-medium">
                    Experience Level*:
                  </p>
                  {experienceLevel.map((item) => (
                    <label
                      key={item.id}
                      className="flex gap-x-2 lg:text-lg sm:text-base text-sm"
                    >
                      <input
                        type="checkbox"
                        {...register(`experienceLevel.${item.value}`)}
                      />
                      {item.title}
                    </label>
                  ))}
                </div>
              </div>

              <div className="xlg:w-[45%] xmd:w-[47%] sm:w-[48%] w-full flex flex-col gap-y-2">
                <div className="flex flex-col gap-y-3">
                  <p className="w-full bg-light-purple-100 py-3 px-3 rounded-2xl outline-none focus:outline-blue-300 text-blue-200 placeholder-blue-200   font-medium">
                    Interview Process*:
                  </p>
                  {interviewProcess.map((item) => (
                    <label
                      key={item.id}
                      className="flex gap-x-2 lg:text-lg sm:text-base text-sm"
                    >
                      <input
                        type="checkbox"
                        {...register(`interviewProcess.${item.value}`)}
                      />
                      {item.title}
                    </label>
                  ))}
                </div>
              </div>
            </div>

            {/* ------- checking above address ------ */}

            <div className="text-sm flex gap-x-2 my-4">
              <input
                type="checkbox"
                className="bg-light-purple-100"
                onChange={handleJobToInterviewSync}
              />
              Same as Job Location
            </div>

            {/* --------- Interview Location ----------- */}

            <div className="flex sm:flex-row flex-col gap-y-4  justify-between">
              <div className="xlg:w-[45%] xmd:w-[47%] sm:w-[48%] w-full flex flex-col gap-y-2">
                <input
                  type="text"
                  placeholder="Interview Location*"
                  className="w-full bg-light-purple-100 py-3 px-3 rounded-2xl outline-none focus:outline-blue-300 text-blue-200 placeholder-blue-200   font-medium"
                  disabled={isInterviewSameAsJob}
                  {...register("interviewLocation", {
                    required: "Interview Location is required",
                  })}
                />
                {errors.interviewLocation && (
                  <p className="text-warning-red text-sm px-2">
                    {errors.interviewLocation.message}
                  </p>
                )}
              </div>
            </div>

            {/* --------- State , City & Pincode ----------- */}

            <div className="grid sm:grid-cols-3 grid-cols-2 gap-y-4  justify-between">
              <div className="w-[90%] flex flex-col gap-y-2">
                <select
                  id="states"
                  {...register("interviewState", {
                    required: "State is required",
                  })}
                  className="w-full bg-light-purple-100 py-3 px-3 rounded-2xl outline-none focus:outline-blue-300 text-blue-200 placeholder-blue-200   font-medium"
                  disabled={isInterviewSameAsJob}
                >
                  <option value="" key={0}>
                    State*
                  </option>
                  {states.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </select>

                {errors.interviewState && (
                  <p className="text-warning-red text-sm px-2">
                    {errors.interviewState.message}
                  </p>
                )}
              </div>

              <div className="w-[90%] flex flex-col gap-y-2">
                <input
                  type="text"
                  placeholder="City*"
                  className="w-full bg-light-purple-100 py-3 px-3 rounded-2xl outline-none focus:outline-blue-300 text-blue-200 placeholder-blue-200   font-medium"
                  {...register("interviewCity", {
                    required: "City is required",
                  })}
                  disabled={isInterviewSameAsJob}
                />
                {errors.interviewCity && (
                  <p className="text-warning-red px-2 text-sm">
                    {errors.interviewCity.message}
                  </p>
                )}
              </div>

              <div className="w-[90%] flex flex-col gap-y-2">
                <input
                  type="text"
                  placeholder="Pincode*"
                  className="w-full bg-light-purple-100 py-3 px-3 rounded-2xl outline-none focus:outline-blue-300 text-blue-200 placeholder-blue-200   font-medium"
                  disabled={isInterviewSameAsJob}
                  {...register("interviewPincode", {
                    required: "Pincode is required",
                    pattern: {
                      value: /^[1-9][0-9]{5}$/, // Matches a valid 6-digit  pincode
                      message: "Enter a valid 6-digit pincode",
                    },
                  })}
                />
                {errors.interviewPincode && (
                  <p className="text-warning-red px-2 text-sm">
                    {errors.interviewPincode.message}
                  </p>
                )}
              </div>
            </div>

            {/* --------- Interview Timing ----------- */}

            <div className="flex sm:flex-row flex-col gap-y-4  justify-between">
              <div className="xlg:w-[45%] xmd:w-[47%] sm:w-[48%] w-full flex flex-col gap-y-2">
                <input
                  type="text"
                  placeholder="Interview Timings*"
                  className="w-full bg-light-purple-100 py-3 px-3 rounded-2xl outline-none focus:outline-blue-300 text-blue-200 placeholder-blue-200   font-medium"
                  {...register("interviewTiming", {
                    required: "Interview Timing is required",
                  })}
                />
                {errors.interviewTiming && (
                  <p className="text-warning-red text-sm px-2">
                    {errors.interviewTiming.message}
                  </p>
                )}
              </div>
            </div>

            {/* --------- Job Description --------- */}

            <div className="flex  flex-col gap-y-4  justify-between">
              <textarea
                placeholder="Job Description*"
                className="w-full sm:h-[400px] h-[320px]  bg-light-purple-100 py-3 px-3 rounded-2xl outline-none focus:outline-blue-300 text-blue-200 placeholder-blue-200   font-medium"
                {...register("jobDescription", {
                  required: "Job Description is required",
                })}
              ></textarea>
              {errors.jobDescription && (
                <p className="text-warning-red text-sm px-2">
                  {errors.jobDescription.message}
                </p>
              )}
            </div>

            {/* ------------ need help and submit btn --------- */}

            <div className="flex xs:flex-row flex-col gap-y-4 xs:justify-between xmd:text-2xl sm:text-xl xs:text-lg text-base xs:items-center">
              {/* -------- need help ---- */}

              <p className="text-blue-400 font-medium">
                Need Help?{" "}
                <span
                  className="cursor-pointer"
                  onClick={() => navigation("/Contact")}
                >
                  Contact Us
                </span>
              </p>

              <button
                type="submit"
                className="rounded-full bg-blue-200 px-8 py-2 text-white border border-blue-200 transition-all duration-300 hover:border-black hover:bg-blue-300 hover:scale-105 w-max"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Desktop4;
