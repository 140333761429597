import { React, useState } from "react";
// import { FaAngleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Popup = ({ closePopup }) => {
  const navigate = useNavigate();

  // Handlers for routing
  const handleSearchJobClick = () => {
    navigate("/Frame-11"); // Replace with your actual route
  };
  
  const handlePostJobClick = () => {
    navigate("/Desktop-3"); // Replace with your actual route
  };

  // Close the popup when the user clicks outside of it
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-10">
      {/* Container with top and bottom color */}
      <div className="relative bg-white w-[600px] h-[300px] rounded-xl shadow-lg text-center m-4">
        {/* Top and Bottom colored strips */}
        <div className="absolute top-0 left-0 w-full h-12 bg-[#3D348B] rounded-t-xl"></div>
        <div className="absolute bottom-0 left-0 w-full h-12 bg-[#3D348B] rounded-b-xl"></div>

        {/* Popup content */}
        <div className="flex justify-between items-center h-full p-12 gap-2">
          {/* Text and buttons */}
          <div className="w-2/3 text-left">
            <h2 className="text-2xl font-semibold text-[#3D348B] sm:mb-8 mb-3 text-center ">
              What are you looking for?
            </h2>

            {/* Search Job Button */}
            <button
              className="bg-blue-50  font-semibold py-3 px-4 mb-4 rounded-full  focus:ring-2 focus:ring-indigo-400 w-full"
              onClick={handleSearchJobClick}
            >
              Search Job
            </button>

            {/* Post Job Button */}
            <button
              className="bg-blue-50 font-semibold py-3 px-6 rounded-full  focus:ring-2 focus:ring-blue-400 w-full"
              onClick={handlePostJobClick}
            >
              Post Job
            </button>
          </div>

          {/* Image on the right side */}
          <div className="w-1/3 sm:mt-0 mt-10">
            <img
              src="./hirersLogo.png" // Use the path to your image here
              alt="Hiree Logo"
              className="w-full h-auto rounded-full"
            />
          </div>
        </div>

        {/* Close Button */}
        <button
          className="absolute top-3 right-3 text-white rounded-full p-2 hover:text-red-600"
          onClick={closePopup}
        >
          X
        </button>
      </div>
    </div>
  );
};

const Navbar = () => {
  const navigate = useNavigate();
  // const handleJob = () => {
  //   navigate("/Frame-11");
  // };
  const handleContact = () => {
    navigate("/Contact");
  };
  // const handlePostJob = () => {
  //   navigate("/Desktop-3");
  // };

  const [showPopup, setShowPopup] = useState(false);

  const handleButtonClick = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <nav className="bg-blue-200 overflow-x-hidden w-[100%]">
      <div className=" flex justify-between items-center ">
        <div>
          <img
            className="sm:h-[80px] h-[70px] rounded-xl xs:pl-6 pl-4 py-1"
            src="/hirersLogo.png"
            alt="logo"
          ></img>
        </div>

        <div className="flex gap-x-4 items-center sm:pr-6 md:pr-10 pr-4">
          {/* <div
            className="text-white text-1xl sm:text-2xl px-2 sm:px-10 font-bold hover:text-rose-400 cursor-pointer"
            onClick={handlePostJob}
          >
            Post Job
          </div>
          <div
            className="text-white text-1xl sm:text-2xl px-2 sm:px-10 cursor-pointer font-bold hover:text-rose-400"
            onClick={handleJob}
          >
            New Job
          </div> */}
          <div
            className="text-white text-base sm:text-lg fnt-semibold  sm:px-10 cursor-pointer transition-all duration-300 hover:text-red-100"
            onClick={handleContact}
          >
            Contact Us
          </div>

          <div
            className="bg-white text-black sm:text-lg text-base rounded-3xl flex items-center px-4 xs:py-2 py-1 cursor-pointer font-semibold  hover:bg-gray"
            // onClick={handleProfile}
            onClick={handleButtonClick}
          >
            Signup/Login
            {/* <FaAngleRight className="ml-2" /> */}
          </div>
        </div>
      </div>
      {showPopup && <Popup closePopup={closePopup} />}
    </nav>
  );
};

export default Navbar;
