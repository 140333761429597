import React from "react";


const Frame3 = () => {
  return (
    <div className=" flex justify-center items-center w-screen h-screen mt-96 sm:mt-1">
      {/* Outer Container with fixed height and overflow handling */}
      <div className="relative w-20vh h-20vh md:w-[100%]  ">
        {/* For Mobile View: Three Blocks */}
        <div className=" grid-cols-1  hidden md:hidden gap-4 mt-5 ">
          <div
            className="flex flex-col justify-center items-center bg-cover bg-center p-4 rounded-lg animate-fadeIn"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/backgroundremove1.png)`,
              height: "200px", // Fixed height for mobile blocks
              overflow: "hidden",
              borderRadius: "10px",
            }}
          >
            <h1 className="font-inter text-[20px] font-[700] text-center text-white">
              About Us
            </h1>
            <p className="font-inter text-[14px] font-[600] text-left text-white mt-2">
              Welcome to Hirers, a dynamic platform committed to reshaping the
              employment landscape in India.
            </p>
          </div>

          <div
            className="flex flex-col justify-center items-center bg-cover bg-center p-4 rounded-lg animate-fadeIn"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/backgroundremove1.png)`,
              backgroundSize: "cover",
              height: "200px", // Fixed height for mobile blocks
              overflow: "hidden",
              borderRadius: "10px",
            }}
          >
            <h2 className="font-abeezee text-[20px] font-[400] text-center text-white">
              #Our Mission
            </h2>
            <p className="font-inter text-[14px] font-[600] text-left text-white mt-2">
              "To revolutionize the job search and recruitment process in India
              by connecting job seekers with employers."
            </p>
          </div>

          <div
            className="flex flex-col justify-center items-center bg-cover bg-cente p-4 rounded-lg animate-fadeIn"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/backgroundremove1.png)`,
              backgroundSize: "cover",
              height: "200px", // Fixed height for mobile blocks
              overflow: "hidden",
              borderRadius: "10px",
            }}
          >
            <h2 className="font-abeezee text-[20px] font-[400] text-center text-white">
              #Our Vision
            </h2>
            <p className="font-inter text-[14px] font-[600] text-left text-white mt-2">
              "To create a future where every individual has access to
              meaningful employment."
            </p>
          </div>
        </div>

        <div className=" md:flex flex-col gap-6 ">
          <div
            className="flex flex-col justify-center mb-3 items-center bg-cover bg-center p-4 min-h-full md:min-h-screen"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/backgroundremove1.png)`,
              backgroundSize: "100%",
              // Allow auto height for desktop
              // borderRadius: 'px',
            }}
          >
            <h1 className="font-inter text-[32px] font-[700] text-center text-white">
              About Usss
            </h1>
            <p className="font-inter text-[24px] font-[600] text-left text-white mt-2">
              Welcome to Hirers, a dynamic platform committed to reshaping the
              employment landscape in India.
            </p>
            <h2 className="font-abeezee text-[32px] font-[400] text-center text-white mt-4">
              #Our Mission
            </h2>
            <p className="font-inter text-[24px] font-[600] text-left text-white mt-2">
              "To revolutionize the job search and recruitment process in India
              by connecting job seekers with employers."
            </p>
            <h2 className="font-abeezee text-[32px] font-[400] text-center text-white mt-4">
              #Our Vision
            </h2>
            <p className="font-inter text-[24px] font-[600] text-left text-white mt-2">
              "To create a future where every individual has access to
              meaningful employment."
            </p>
          </div>
        </div>
      </div>

      {/* Add styles for fade-in animation */}
      <style jsx>{`
        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .animate-fadeIn {
          animation: fadeIn 0.5s ease-in-out forwards;
        }
      `}</style>
    </div>
  );
};

export default Frame3;
