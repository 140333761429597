import React from "react";
import { useNavigate } from "react-router-dom";
import Hirers from "./Why_Hirers_New";
import Cards from "./Cards_New";
import Footer from "./Footer_New";
import Navbar from "./Navbar";
import HorizontalScroll from "./companys_scroll";
import Frame3 from "../Frames/Frame3";
import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Cards2 = () => {
  const [showHeading, setShowHeading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const intervalOfHead = setInterval(() => {
      setShowHeading((prev) => !prev);
    }, 5000);

    return () => clearInterval(intervalOfHead);
  }, []);

  const socialIcon = [
    {
      id: 1,
      title: "whatsapp",
      icon: "/whatsapp-1.png",
      path: "https://wa.me/YOUR_NUMBER",
    },
    {
      id: 2,
      title: "facebook",
      icon: "/facebook-1.png",
      path: "https://www.facebook.com/hirers.co.in/",
    },
    {
      id: 3,
      title: "instagram",
      icon: "/instagram-1.png",
      path: "https://www.instagram.com/hirers.co.in/",
    },
    {
      id: 4,
      title: "linkeIn",
      icon: "/linkedin-1.png",
      path: "https://www.linkedin.com/company/hirers-co-in/?viewAsMember=true",
    },
  ];

  //Post job - /Desktop-3
  //Serach job = /Frame-11

  // Static header colors (removed the dynamic color change logic)
  const headerColor1 = "#393285"; // Example static color
  return (
    <div className="mt-30" id="home">
      <Navbar className="sm:w-screen" />
      <div
        className="h-screen sm:w-full  flex-row justify-center items-center relative hidden"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/background.png)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="p-2 w-[80%] h-screen ">
          {/* Header Text Section */}
          <div className="p-2 flex flex-col justify-center items-center w-full h-1/5 mr-10">
            <h1
              className="flex flex-col md:flex-row justify-center items-center font-bold capitalize text-lg md:text-5xl"
              style={{
                color: headerColor1,
              }}
            >
              find ,apply, and secure
            </h1>
            <h1
              className="font-bold capitalize text-xl md:text-5xl text-center"
              style={{
                color: headerColor1,
              }}
            >
              Your Dream Job with Hirers in Just 2 Days!!
            </h1>
            <h3
              className="capitalize text-center text-lg font-bold md:text-4xl mt-2 transition-all duration-500 hover:text-[#6b6fc9]"
              style={{
                color: "black",
                textShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
                transition: "color 0.5s ease-in-out, transform 0.5s",
              }}
            >
              Experience the accelerated journey to your dream career with us.
            </h3>
            {/* Social Media Icons - Positioned below in mobile view */}
            <div className="absolute md:top-[20%] top-[16%] md:right-5 right-0 flex flex-row md:flex-col items-center justify-center space-y-4 md:space-y-4 sm:space-x-4 w-full md:w-auto mt-5 px-4 py-4">
              <a
                href="https://www.instagram.com/hirers.co.in/"
                target="_blank"
                rel="noopener noreferrer"
                className="p-3 rounded-full bg-purple-100 hover:shadow-lg ml-5 transition-shadow duration-300 ease-in-out transform hover:scale-110 mt-4 mr-2"
              >
                <LazyLoadImage
                  src="/instagramLogo.png"
                  alt="Instagram Logo"
                  className="w-8 h-8 md:w-10 md:h-10"
                />
              </a>
              <a
                href="https://www.facebook.com/hirers.co.in/"
                target="_blank"
                rel="noopener noreferrer"
                className="p-3 rounded-full bg-purple-100 hover:shadow-lg transition-shadow duration-300 ease-in-out transform hover:scale-110 mr-2"
              >
                <LazyLoadImage
                  src="/facebook_logo.png"
                  alt="Facebook Logo"
                  className="w-8 h-8 md:w-10 md:h-10"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/hirers-co-in/?viewAsMember=true"
                target="_blank"
                rel="noopener noreferrer"
                className="p-3 rounded-full bg-purple-100 hover:shadow-lg transition-shadow duration-300 ease-in-out transform hover:scale-110 mr-2"
              >
                <LazyLoadImage
                  src="/linkedinLogo.png"
                  alt="LinkedIn Logo"
                  className="w-8 h-8 md:w-10 md:h-10"
                />
              </a>
              <a
                href="https://wa.me/YOUR_NUMBER"
                target="_blank"
                rel="noopener noreferrer"
                className="p-3 rounded-full bg-purple-100 hover:shadow-lg transition-shadow duration-300 ease-in-out transform hover:scale-110 mr-2"
              >
                <LazyLoadImage
                  src="/what.png"
                  alt="WhatsApp Logo"
                  className="w-8 h-8 md:w-10 md:h-10"
                />
              </a>
            </div>
          </div>
          {/* Main Cards Section */}
          <div className="flex items-center flex-col-reverse lg:flex-row justify-evenly p-6 mb-20 lg:mb-20 lg:h-auto space-x-10">
            {/* Employer Card with Image on Left Side */}
            <div className="flex flex-col items-center gap-6 lg:mt-0 w-full h-[470px] mt-36 ml-6">
              <div className="bg-purple-100 border rounded-lg p-6 shadow-lg block sm:flex w-full h-full items-center transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
                <LazyLoadImage
                  className="w-[200px] h-[200px]  object-contain transition-transform duration-300 transform hover:scale-110"
                  src="/employer.png"
                  alt="Employer"
                />
                <div className="text-left flex-1 ml-4">
                  <h2
                    className="text-[#393285] mb-4 drop-shadow-lg"
                    style={{
                      fontFamily: "Inter",
                      fontSize: "32px",
                      fontWeight: "700",
                      lineHeight: "38.73px",
                      textAlign: "left",
                    }}
                  >
                    For Employers
                  </h2>
                  <p
                    className="text-black leading-relaxed drop-shadow-md"
                    style={{
                      fontFamily: "Inter",
                      fontSize: "24px",
                      fontWeight: "600",
                      lineHeight: "29.05px",
                      textAlign: "left",
                    }}
                  >
                    Find verified candidates from a large pool of filtered
                    professionals!!
                  </p>
                  <LazyLoadImage
                    className="mt-4 transition-transform duration-300 transform hover:scale-110"
                    src="/Arrow.png"
                    alt="Arrow pointing down"
                    style={{ width: "120px", height: "60px" }}
                  />
                </div>
              </div>
              <button
                className="sm:w-1/4 w-1/2 rounded-lg px-5 py-3 text-white capitalize bg-[#393285] hover:bg-gradient-to-r hover:from-[#4a47a3] hover:to-[#5c59c0] transform hover:scale-110 transition-transform duration-300 ease-in-out shadow-md hover:shadow-lg mt-6"
                onClick={() => navigate("/Desktop-3")}
                style={{
                  fontFamily: "ABeeZee",
                  fontSize: "20px",
                  fontWeight: "200",
                  lineHeight: "37.82px",
                  textAlign: "center",
                  marginBottom: "12px",
                }}
              >
                Post Job
              </button>
            </div>

            {/* Job Seekers Card with Image on Right Side */}
            <div className="flex flex-col items-center gap-6 mt-14 lg:mt-0 w-full h-[470px]">
              <div className="bg-purple-100 border rounded-lg p-6 shadow-lg sm:flex block w-full h-full items-center transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
                <div className="text-left flex-1 mr-4">
                  <h2
                    className="text-[#393285] mb-4 drop-shadow-lg"
                    style={{
                      fontFamily: "Inter",
                      fontSize: "32px",
                      fontWeight: "700",
                      lineHeight: "38.73px",
                      textAlign: "left",
                    }}
                  >
                    For Job Seekers
                  </h2>
                  <p
                    className="text-black leading-relaxed drop-shadow-md"
                    style={{
                      fontFamily: "Inter",
                      fontSize: "24px",
                      fontWeight: "600",
                      lineHeight: "29.05px",
                      textAlign: "left",
                    }}
                  >
                    Get 100% verified jobs at no cost!!
                  </p>
                  <LazyLoadImage
                    className="mt-4 transition-transform duration-300 transform hover:scale-110"
                    src="/Arrowupdate1.png"
                    alt="Arrow pointing down"
                    style={{ width: "120px", height: "60px" }}
                  />
                </div>
                <LazyLoadImage
                  className="w-[200px] h-[200px]  ml-16  object-contain transition-transform duration-300 transform hover:scale-110"
                  src="/jobseekers.png"
                  alt="Job Seeker"
                />
              </div>
              <button
                className="sm:w-1/4 w-1/2 rounded-lg px-5 py-3 text-white capitalize bg-[#393285] hover:bg-gradient-to-r hover:from-[#4a47a3] hover:to-[#5c59c0] transform hover:scale-110 transition-transform duration-300 ease-in-out shadow-md hover:shadow-lg mt-6"
                onClick={() => navigate("/Frame-11")}
                style={{
                  fontFamily: "ABeeZee",
                  fontSize: "20px",
                  fontWeight: "200",
                  lineHeight: "37.82px",
                  textAlign: "center",
                  marginBottom: "12px",
                }}
              >
                Search Job
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* ------ new hero section ----- */}

      <section className="min-h-[460px] sm:pt-16 xs:pt-16 pt-12 sm:pb-20 pb-12 bg-center xxxl:bg-cover sm:bg-contain bg-contain bg-no-repeat hero-section relative ">
        {/* ---------- container --------- */}

        <div className="lg:w-10/12 w-11/12 mx-auto  h-full flex flex-col xmd:gap-y-36 sm:gap-y-24 gap-y-16">
          {/* -------- upper part ------- */}

          <AnimatePresence mode="wait">
            {showHeading ? (
              <motion.div
                key="heading1"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
                className="flex flex-col xmd:gap-y-4 gap-y-4 xs:px-0 px-6 shadow-part "
              >
                <div className="flex flex-col xmd:gap-y-4 gap-y-1 items-center text-center xxl:text-5xl xmd:text-4xl md:text-3xl xs:text-2xl text-xl font-bold text-blue-200 tracking-wide drop-shadow-lg">
                  <h1 className="drop-shadow-lg sm:drop-shadow-none">
                    Find, Apply, and Secure
                  </h1>

                  <h1 className="drop-shadow-lg sm:drop-shadow-none">
                    Your Dream Job with Hirers in Just 2 Days!
                  </h1>
                </div>

                <p
                  style={{
                    color: "black",
                    textShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
                    transition: "color 0.5s ease-in-out, transform 0.5s",
                  }}
                  className="xxl:text-[28px] xmd:text-2xl md:text-xl text-lg sm:flex hidden justify-center text-center drop-shadow-2xl font-abeezee"
                >
                  Experience the accelerated journey to your dream career with
                  us.
                </p>

                <div className="  flex xmd:hidden gap-x-4 justify-center">
                  {socialIcon.map((item) => (
                    <a
                      href={item.path}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={item.id}
                      className="xxl:w-14 xmd:w-10 w-9 h-9 rounded-full transition-all duration-300 hover:scale-105 cursor-pointer"
                    >
                      <LazyLoadImage
                        src={item.icon}
                        alt={item.title}
                        effect="blur"
                        className="w-full h-full object-cover rounded-full"
                      />
                    </a>
                  ))}
                </div>
              </motion.div>
            ) : (
              <motion.div
                key="heading-2"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
                className="flex flex-col xmd:gap-y-4 gap-y-4 xs:px-0 px-2 shadow-part "
              >
                <div className="flex flex-col xmd:gap-y-4 gap-y-1 items-center text-center xxl:text-5xl xmd:text-4xl md:text-3xl xs:text-2xl text-xl font-bold text-blue-200 tracking-wide drop-shadow-lg ">
                  <h1 className="drop-shadow-lg sm:drop-shadow-none">
                    Elevate your team with Hirers!
                  </h1>

                  <h1 className="drop-shadow-lg sm:drop-shadow-none ">
                    Post, find and empower your organization.
                  </h1>
                </div>

                <p
                  style={{
                    color: "black",
                    textShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
                    transition: "color 0.5s ease-in-out, transform 0.5s",
                  }}
                  className="xxl:text-[28px] xmd:text-2xl md:text-xl text-lg sm:flex hidden justify-center text-center drop-shadow-2xl font-abeezee"
                >
                  Join the journey with Hirers towards building a stronger, more
                  accomplished team.
                </p>

                <div className="  flex xmd:hidden gap-x-4 justify-center">
                  {socialIcon.map((item) => (
                    <a
                      href={item.path}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={item.id}
                      className="xxl:w-14 xmd:w-10 w-9 h-9 rounded-full transition-all duration-300 hover:scale-105 cursor-pointer"
                    >
                      <LazyLoadImage
                        src={item.icon}
                        alt={item.title}
                        effect="blur"
                        className="w-full h-full object-cover rounded-full"
                      />
                    </a>
                  ))}
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          {/* --------- bottom part ---------- */}

          <div className="flex xmd:flex-row flex-col-reverse justify-center items-center xxl:gap-x-36 gap-y-16 lg:gap-x-32 gap-x-16 ">
            {/* --------- employers ------ */}

            <div className="flex flex-col sm:gap-y-8 gap-y-4">
              <div className="xxl:w-[380px] sm:w-[350px] w-[280px] xxl:h-[330px] sm:h-[280px] h-[260px] bg-light-purple-100 rounded-2xl  relative">
                <div className="xxl:pl-40 pl-32 pr-3 py-4 flex flex-col sm:gap-y-4 gap-y-2">
                  <h2 className="xmd:text-2xl sm:text-xl text-lg font-bold text-blue-200">
                    For Employers
                  </h2>
                  <p className="xxl:text-[22px] md:text-xl sm:text-lg text-base leading-7 font-semibold text-light-black">
                    Hire from a large pool of filtered candidates!!
                  </p>
                </div>

                {/* -------- employer img ------ */}
                <div className="absolute sm:left-[-150px] left-[-80px] xxl:top-[-90px] sm:top-[-50px] top-[-10px] ">
                  <LazyLoadImage
                    src="/employer.png"
                    alt="employers"
                    effect="blur"
                    className="xxl:w-[400px] sm:w-[300px] w-[220px]"
                  />
                </div>

                {/* -------- arrow img ----- */}

                <div className="absolute xmd:right-[30px] right-[40px] sm:top-[180px] top-[160px] xmd:rotate-[220deg] rotate-[240deg] ">
                  <LazyLoadImage
                    src="/arrow-acw.png"
                    alt="arrow"
                    effect="blur"
                    className="xxl:w-[100px] sm:w-[80px] w-[60px]"
                  />
                </div>
              </div>

              <div className="flex justify-center">
                <button
                  onClick={() => navigate("/Desktop-3")}
                  className="py-3 lg:px-8 px-6 bg-blue-200 text-white rounded-2xl lg:text-2xl sm:text-xl transition-all duration-300 hover:scale-105 text-lg"
                >
                  Post Job
                </button>
              </div>
            </div>

            {/* -------- jobseekers ---------- */}

            <div className="flex flex-col sm:gap-y-8 gap-y-4">
              <div className="xxl:w-[380px] sm:w-[350px] w-[280px] xxl:h-[330px] sm:h-[280px] h-[260px] bg-light-purple-100 rounded-2xl  relative">
                <div className="xxl:pr-44 sm:pr-32 pr-28 pl-4 py-4 flex flex-col sm:gap-y-4 gap-y-2">
                  <h2 className="xmd:text-2xl sm:text-xl text-lg font-bold text-blue-200">
                    For JobSeekers
                  </h2>
                  <p className="xxl:text-[22px] md:text-xl sm:text-lg text-base leading-7 font-semibold text-light-black">
                    Get 100% verified Jobs at no <br></br>
                    cost!!
                  </p>
                </div>

                {/* -------- jobseeker -------- */}

                <div className="absolute xxl:right-[-150px] sm:right-[-120px] right-[-60px] xxl:top-[-90px] sm:top-[-50px] top-[-10px] ">
                  <LazyLoadImage
                    src="/job_seekers.png"
                    alt="jobseeker"
                    effect="blur"
                    className="xxl:w-[400px] sm:w-[300px] w-[220px]"
                  />
                </div>

                {/* -------- arrow img ----- */}

                <div className="absolute left-[30px] sm:top-[180px] top-[160px] xmd:rotate-[130deg] rotate-[100deg]">
                  <LazyLoadImage
                    src="/arrow-cw.png"
                    alt="arrow"
                    effect="blur"
                    className="xxl:w-[100px] sm:w-[80px] w-[60px]"
                  />
                </div>
              </div>

              <div className="flex justify-center">
                <button
                  onClick={() => navigate("/Frame-11")}
                  className="py-3 lg:px-8 px-6 bg-blue-200 text-white rounded-2xl lg:text-2xl sm:text-xl text-lg transition-all duration-300 hover:scale-105"
                >
                  Search Job
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* -------- side social icons -------- */}

        <div className="w-max  xmd:flex hidden flex-col xl:gap-y-6 gap-y-4 absolute xlg:top-[30%] lg:top-[20%] top-[15%] xxl:right-16 xl:right-10 xlg;xlg:right-8 right-6">
          {socialIcon.map((item) => (
            <a
              href={item.path}
              target="_blank"
              rel="noopener noreferrer"
              key={item.id}
              className="xxl:w-14 w-10 transition-all duration-300 hover:scale-105 cursor-pointer"
            >
              <LazyLoadImage
                src={item.icon}
                alt={item.title}
                effect="blur"
                className="w-full h-full object-cover"
              />
            </a>
          ))}
        </div>
      </section>

      <Frame3 />
      <Cards />
      <Hirers />
      <HorizontalScroll data="Our Customers" />
      {/* <Scroll/> */}
      <Footer />
    </div>
  );
};

export default Cards2;
