import React, { useEffect, useState } from "react";
import Navbar1 from "./Navbar-3&5/navbar1";
import axios from "axios";
import { useParams } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import { jobsData } from "../../data/jobsData";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";

const CandidateCard = ({
  id,
  name,
  resume,
  dob,
  profile,
  gender,
  mobileNumber,
  email,
  currentAddress,
  education,
  language,
  video,
  pastjobexperience,
  work_experience,
  skills,
  logo,
  appliedDate,
  status,
  updateJobStatus,
  // selectedCity,
  response,
}) => {
  const [dayDifference, setDayDifference] = useState("");

  useEffect(() => {
    const [day, month, year] = response.dob.split("-");
    const date = new Date(`${year}-${month}-${day}`);

    const givenDate = date.toISOString().split("T")[0];

    const newDate = new Date(givenDate);
    const todaydate = new Date();

    // Calculate the difference in days
    const diffTime = todaydate - newDate;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    setDayDifference(diffDays - 1);
  }, [response.dob]);

  return (
    <div className=" xs:px-7 px-4 xs:py-6 py-4 rounded-2xl bg-light-purple-100 flex smd:justify-between">
      {/* ----------- profile info ---------- */}
      <div className="lg::w-[76%] w-[73%] smd:grid hidden xlg:grid-cols-3 grid-cols-2  xl:gap-x-4 font-abeezee xxl:text-lg text-base font-normal">
        {/* ------- 1st part ----- */}

        <div className="flex gap-x-3 items-starts  ">
          {/* ----- profile img ---- */}

          <div className="xlg:w-[70px] xlg:h-[70px] w-[56px] h-[56px] py-1 px-2 rounded-[20px] bg-white">
            <img
              src={response.profile ? response.profile : "/profile.jpg"}
              alt="Profile"
              className="w-full h-full object-cover"
            />
            {/* <span>{response.profile}</span> */}
          </div>

          {/* -------- personal info  ---- */}

          <div className="flex flex-col gap-y-2">
            <p>{response.name}</p>
            <p>
              {response.dob} , {response.gender}
            </p>
            <p>{response.currentAddress}</p>
            <p>{response.education}</p>
            <p>{response.workexperience}</p>
            <p>{response.skills}</p>
          </div>
        </div>

        {/* ------ 2nd part ------ */}

        <div className="xlg:flex hidden flex-col gap-y-4 justify   px-4 ">
          <a href={response.resume}>View resume/CV</a>
          <p>{response.mobileNumber} </p>
          <p>{response.language}</p>
          <p>{response.workexperience} years</p>
        </div>

        {/* ------ 3rd part ------ */}

        <div className="hidden xlg:flex flex-col xlg:gap-y-16 gap-y-2 justify   ">
          <p>{response.email} </p>
          <a href={response.video}>Introduction audio/video</a>
        </div>

        <div className="flex xlg:hidden flex-col xlg:gap-y-4 gap-y-2 justify   px-4 ">
          <p>View resume/CV</p>
          <p>{response.mobileNumber} </p>
          <p>{response.languagesKnown}</p>
          <p>{response.workExperience} years</p>
          <p>{response.email} </p>
          <p>Introduction audio/video</p>
        </div>
      </div>

      {/* ------ btns ------- */}

      <div className="xlg:w-[18%] lg:w-[22%] w-[25%]   smd:flex hidden flex-col justify-between items-center">
        <div className="flex flex-col items-center gap-5 font-semibold xl:text-xl xmd:text-lg text-base">
          {/* Shortlist Button */}
          {response.status !== "shortlisted" && (
            <button
              className=" text-green-100 bg-white hover:scale-105 hover:bg-green-100 hover:text-white flex items-center justify-center  py-2 lg:px-4 px-2 rounded-2xl h-9 w-full md:w-36 sm:w-28 transition-all duration-300 ease-in-out"
              onClick={() => updateJobStatus(response.id, "shortlisted")}
            >
              Shortlist
            </button>
          )}
          {response.status === "shortlisted" && (
            <button className="bg-green-100 text-white flex items-center justify-center py-2 lg:px-4 px-2 rounded-2xl h-9 w-full md:w-36 sm:w-28 cursor-not-allowed">
              Shortlisted
            </button>
          )}

          {/* Reject Button */}
          {response.status !== "rejected" && (
            <button
              className="bg-white text-red-100 hover:scale-105 hover:bg-red-100 hover:text-white flex items-center justify-center py-2 lg:px-4 px-2 rounded-2xl h-9 w-full md:w-36 sm:w-28 transition-all duration-300 ease-in-out"
              onClick={() => updateJobStatus(response.id, "rejected")}
            >
              Reject
            </button>
          )}
          {response.status === "rejected" && (
            <button className="bg-red-100 text-white flex items-center justify-center py-2lg:px-4 px-2 rounded-2xl h-9 w-full md:w-36 sm:w-28 cursor-not-allowed">
              Rejected
            </button>
          )}
        </div>
        <p className="xmd:text-base text-sm font-normal">{`Applied ${dayDifference} days ago`}</p>
      </div>

      {/* ------- for mobile design -------- */}

      <div className="flex  flex-col gap-y-4 smd:hidden   w-full ">
        <div className="flex items-center gap-x-6 sm:text-base text-sm">
          <div className=" xs:w-[68px] xs:h-[68px] w-[62px] h-[62px] py-1 px-2 rounded-[20px] bg-white">
            <img
              src={response.profile ? response.profile : "/profile.jpg"}
              alt="Profile"
              className="w-full h-full object-cover"
            />
          </div>

          <div className="flex flex-col gap-y-1">
            <p>{response.name}</p>
            <p>
              {response.dob} , {response.gender}
            </p>
            <p>{response.mobileNumber} </p>
          </div>
        </div>

        <div className="flex justify-between   w-full sm:text-base text-sm">
          {/* ----- profile info ---- */}

          <div className="flex flex-col gap-y-1">
            <p>{response.email}</p>
            <p>{response.currentAddress}</p>
            <p>{response.education}</p>
            <p>{response.language}</p>
            <p>{response.workexperience}</p>
            <p>{response.workexperience} years</p>
            <p>{response.skills}</p>
            <a href={response.video}>Introduction audio/video</a>
            <a href={response.resume}>View resume/CV</a>
          </div>

          {/* ------ btns ----- */}

          <div className="xlg:w-[18%] lg:w-[22%] smd:w-[25%]    flex flex-col justify-between items-center">
            <div className="flex flex-col items-center gap-5 font-semibold xl:text-xl xmd:text-lg sm:text-base text-sm">
              {/* Shortlist Button */}
              {response.status !== "shortlisted" && (
                <button
                  className=" text-green-100 bg-white hover:scale-105 hover:bg-green-100 hover:text-white flex items-center justify-center  sm:py-2 py-1 lg:px-4 px-2 rounded-2xl h-9 w-full md:w-36 sm:w-28 transition-all duration-300 ease-in-out"
                  onClick={() => updateJobStatus(response.id, "shortlisted")}
                >
                  Shortlist
                </button>
              )}
              {response.status === "shortlisted" && (
                <button className="bg-green-100 text-white flex items-center justify-center py-2 lg:px-4 px-2 rounded-2xl h-9 w-full md:w-36 sm:w-28 cursor-not-allowed">
                  Shortlisted
                </button>
              )}

              {/* Reject Button */}
              {response.status !== "rejected" && (
                <button
                  className="bg-white text-red-100 flex items-center justify-center py-2 lg:px-4 px-2 rounded-2xl h-9 w-full md:w-36 sm:w-28 transition-all duration-300 ease-in-out"
                  onClick={() => updateJobStatus(response.id, "rejected")}
                >
                  Reject
                </button>
              )}
              {response.status === "rejected" && (
                <button className="bg-red-100 text-white hover:scale-105 hover:bg-red-100 hover:text-white flex items-center justify-center py-2lg:px-4 px-2 rounded-2xl h-9 w-full md:w-36 sm:w-28 cursor-not-allowed">
                  Rejected
                </button>
              )}
            </div>
            <p className="xmd:text-base text-sm font-normal text-center">{`Applied ${dayDifference} days ago`}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Section3 = () => {
  const navigate = useNavigate();
  const [candidatesData, setCandidatesData] = useState([]);
  const [loading, setLoading] = useState(false); //change for styling
  // const [error, setError] = useState(null);
  const [filteredCandidates, setFilteredCandidates] = useState(candidatesData);

  //--------------------------------------------------------------------------

  //Mushafiya Added: for pagination

  const job = jobsData.jobs[0];

  const responseData = jobsData.jobs[0].responses;
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;

  //Calculate total pages

  const totalPages = Math.ceil(responseData.length / itemsPerPage);

  const currentResponseData = responseData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageNumber = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  //------------------------------------------------------------------------

  const { jobId } = useParams();
  const token = localStorage.getItem("token");
  const handleShowAll = () => {
    setFilteredCandidates(candidatesData);
  };

  const handleShowApproved = () => {
    const approvedCandidates = candidatesData.filter(
      (candidate) => candidate.status === "shortlisted"
    );
    setFilteredCandidates(approvedCandidates);
  };

  const handleShowRejected = () => {
    const rejectedCandidates = candidatesData.filter(
      (candidate) => candidate.status === "rejected"
    );
    setFilteredCandidates(rejectedCandidates);
  };

  useEffect(() => {
    setFilteredCandidates(candidatesData); // Update state when candidates change
  }, [candidatesData]);

  const updateJobStatus = (candidateId, status) => {
    axios
      .patch(
        `${process.env.REACT_APP_PORT}/profileReview/${jobId}/${candidateId}`,
        {
          status,
        }
      )
      .then((response) => {
        // console.log(response.data);
        setCandidatesData(
          candidatesData.map((candidate) =>
            candidate.id === candidateId ? { ...candidate, status } : candidate
          )
        );
      })
      .catch((error) => {
        console.error("Error updating job status:", error);
        // setError(
        //   error.response
        //     ? error.response.data.error
        //     : "Failed to update job status."
        // );
      });
  };

  useEffect(() => {
    const fetchApplicants = async () => {
      try {
        // console.log("token", jobId, token);
        const response = await axios.post(
          `${process.env.REACT_APP_PORT}/api/getApplicants/${jobId}`,
          {
            jobId, // Replace with the actual jobId
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // console.log("Applicants data:", response.data);
        setCandidatesData(response.data); // Assuming you have a state to hold applicants data
      } catch (err) {
        // setError(err.message);
        toast.error("Session Expired! Please login again");
        navigate("/");
      } finally {
        setLoading(false);
      }
    };

    fetchApplicants();
  }, [jobId, token, navigate]);

  const approvedCount = candidatesData.filter(
    (candidate) => candidate.status === "shortlisted"
  ).length;
  const rejectedCount = candidatesData.filter(
    (candidate) => candidate.status === "rejected"
  ).length;

  return (
    <>
      <div>
        {loading ? (
          <HashLoader
            color="#34a5f3"
            size={60} // Adjust the size as needed
            cssOverride={{
              position: "absolute", // Ensure the loader is positioned relative to the viewport
              top: "50%", // Center vertically
              left: "50%", // Center horizontally
              transform: "translate(-50%, -50%)", // Adjust to truly center the loader
            }}
          />
        ) : (
          <div>
            <Navbar1 />
            <section className="py-12">
              <div className="w-[95%] mx-auto   flex items-start  font-inter">
                {/* ------ Back btn ----- */}
                <div
                  onClick={() => navigate(-1)}
                  className="xl:w-[10%] w-max  md:flex hidden items-center gap-x-2 cursor-pointer"
                >
                  <span className="xmd:text-xl text-lg">
                    <FaArrowLeftLong />
                  </span>
                  <p className="lg:text-2xl xmd:text-xl text-lg">Back</p>
                </div>

                {/* ------- ui ---- */}

                <div className="xl:w-[80%] md:w-[90%] sm:w-[95%] w-full  mx-auto   flex flex-col gap-y-4 items-center">
                  <h2 className="text-center xl:text-[28px] text-xl md:font-semibold font-bold text-blue-200 uppercase">
                    {job.companyName} - {job.jobTitle}
                  </h2>

                  <div className="bg-light-purple-100 flex sm:justify-evenly justify-between w-full py-2 rounded-2xl xl:text-xl lg:text-lg text-base font-abeezee xs:px-4 px-2">
                    {/* <p className="bg-white rounded-[20px] px-12 py-2 smd:flex hidden ">{`All Candidates (${candidatesData.length})`}</p> */}
                    <button
                      className="bg-white rounded-[20px] px-12 py-2 smd:flex hidden "
                      onClick={handleShowAll}
                    >
                      All Candidates({candidatesData.length})
                    </button>
                    <button
                      className="bg-white rounded-[20px] px-12 py-2 smd:flex hidden "
                      onClick={handleShowApproved}
                    >
                      Shortlisted({approvedCount})
                    </button>
                    <button
                      className="bg-white rounded-[20px] px-12 py-2 smd:flex hidden "
                      onClick={handleShowRejected}
                    >
                      Rejected({rejectedCount})
                    </button>
                  </div>

                  {/* ----------- page number section --------- */}

                  <div className="flex justify-between w-full">
                    {/* ----- extra div for styling ---- */}

                    <div className="sm:flex hidden"></div>

                    {/* -------- show data per page ------- */}
                    <div className="xl:text-xl xmd:text-lg text-base flex gap-x-2 items-center">
                      Show{" "}
                      <span className="py-1 xm:px-4 px-2 rounded-lg bg-light-purple-100">{` ${currentResponseData.length} `}</span>{" "}
                      per page
                    </div>

                    {/* ------- page number ------- */}

                    <div className="flex items-center gap-x-3 xmd:text-xl sm:text-lg text-base">
                      <span
                        className="cursor-pointer"
                        onClick={() =>
                          handlePageNumber(
                            currentPage === 1 ? currentPage : currentPage - 1
                          )
                        }
                      >
                        <FaArrowLeft />
                      </span>
                      <span className="xmd:py-2 py-1 xmd:px-6 xs:px-4 px-3 rounded-lg bg-light-purple-100">
                        {currentPage}
                      </span>
                      <span
                        className="cursor-pointer"
                        onClick={() =>
                          handlePageNumber(
                            currentPage === totalPages
                              ? currentPage
                              : currentPage + 1
                          )
                        }
                      >
                        <FaArrowRight />
                      </span>
                    </div>
                  </div>

                  {/* ---------- responses section ------------ */}

                  <div className="flex flex-col gap-y-4 w-full mt-4">
                    {/* --------- responses ------ */}

                    {filteredCandidates.map((response) => (
                      <CandidateCard
                        key={response.id}
                        response={response}
                        updateJobStatus={updateJobStatus}
                      />
                    ))}
                  </div>

                  {/* ----------- page number section --------- */}

                  <div className="flex justify-between w-full">
                    {/* ----- extra div for styling ---- */}

                    <div className="sm:flex hidden"></div>

                    {/* -------- show data per page ------- */}
                    <div className="xl:text-xl xmd:text-lg text-base flex gap-x-2 items-center">
                      Show{" "}
                      <span className="py-1 xm:px-4 px-2 rounded-lg bg-light-purple-100">{` ${currentResponseData.length} `}</span>{" "}
                      per page
                    </div>

                    {/* ------- page number ------- */}

                    <div className="flex items-center gap-x-3 xmd:text-xl sm:text-lg text-base">
                      <span
                        className="cursor-pointer"
                        onClick={() =>
                          handlePageNumber(
                            currentPage === 1 ? currentPage : currentPage - 1
                          )
                        }
                      >
                        <FaArrowLeft />
                      </span>
                      <span className="xmd:py-2 py-1 xmd:px-6 xs:px-4 px-3 rounded-lg bg-light-purple-100">
                        {currentPage}
                      </span>
                      <span
                        className="cursor-pointer"
                        onClick={() =>
                          handlePageNumber(
                            currentPage === totalPages
                              ? currentPage
                              : currentPage + 1
                          )
                        }
                      >
                        <FaArrowRight />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </div>
    </>
  );
};

export default Section3;
