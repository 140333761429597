import React from "react";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const TermsConditions = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen p-5">
      <div className="max-w-4xl mx-auto p-4 relative">
        {/* Back Button */}
        <button
          onClick={() => navigate('/')}
          className="absolute sm:top-4 top-0 sm:left-4 left-1 flex items-center gap-2 text-primary-blue text-lg sm:text-2xl "
        >
          <IoArrowBackCircleSharp className="text-2xl sm:text-3xl" />
          <span>Back</span>
        </button>

        {/* Terms & Conditions Content */}
        <h1 className="text-2xl sm:text-4xl font-bold mb-4 text-center sm:mt-4">
          Terms & Conditions
        </h1>
        <p className="text-gray-600 text-sm mb-2 text-center">
          Last updated on: 02-10-2024 16:15:33
        </p>
        <p className="text-gray-700 leading-relaxed text-start mb-4 mt-2">
          These Terms and Conditions, along with privacy policy or other terms
          (“Terms”) constitute a binding agreement by and between HIRERS, (
          “Website Owner” or “we” or “us” or “our”) and you (“you” or “your”)
          and relate to your use of our website, goods (as applicable) or
          services (as applicable) (collectively, “Services”).
        </p>
        <p className="text-gray-700 leading-relaxed text-start mb-4">
          By using our website and availing the Services, you agree that you
          have read and accepted these Terms (including the Privacy Policy). We
          reserve the right to modify these Terms at any time and without
          assigning any reason. It is your responsibility to periodically review
          these Terms to stay informed of updates.
        </p>
        <p className="text-gray-700 leading-relaxed">
          The use of this website or availing of our Services is subject to the
          following terms of use:
        </p>
        <ul className="list-disc pl-6 mt-4 space-y-2 text-gray-700">
          <li>
            To access and use the Services, you agree to provide true, accurate
            and complete information to us during and after registration, and
            you shall be responsible for all acts done through the use of your
            registered account.
          </li>
          <li>
            Neither we nor any third parties provide any warranty or guarantee
            as to the accuracy, timeliness, performance, completeness, or
            suitability of the information and materials offered on this website
            or through the Services, for any specific purpose.
          </li>
          <li>
            Your use of our Services and the website is solely at your own risk
            and discretion. You are required to independently assess and ensure
            that the Services meet your requirements.
          </li>
          <li>
            The contents of the Website and the Services are proprietary to us,
            and you will not have any authority to claim any intellectual
            property rights, title, or interest in its contents.
          </li>
          <li>
            You acknowledge that unauthorized use of the Website or the Services
            may lead to action against you as per these Terms or applicable
            laws.
          </li>
          <li>
            You agree not to use the website and/ or Services for any purpose
            that is unlawful, illegal, or forbidden by these Terms, or Indian or
            local laws that might apply to you.
          </li>
          <li>
            You agree and acknowledge that the website and the Services may
            contain links to other third-party websites. On accessing these
            links, you will be governed by the terms of use, privacy policy, and
            such other policies of such third-party websites.
          </li>
          <li>
            You understand that upon initiating a transaction for availing the
            Services you are entering into a legally binding and enforceable
            contract with us for the Services.
          </li>
          <li>
            Refunds can be claimed in case we are unable to provide the Service,
            within the stipulated timelines provided in our policies.
          </li>
          <li>
            Notwithstanding anything contained in these Terms, the parties shall
            not be liable for any failure to perform an obligation under these
            Terms if performance is prevented or delayed by a force majeure
            event.
          </li>
          <li>
            These Terms and any dispute or claim relating to it shall be
            governed by the laws of India.
          </li>
          <li>
            All disputes arising out of or in connection with these Terms shall
            be subject to the exclusive jurisdiction of the courts in Delhi.
          </li>
          <li>
            Concerns or communications relating to these Terms must be addressed
            using the contact information provided on this website.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TermsConditions;
