import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
// import { useParams } from "react-router-dom";
import Navemployee from "../Sections/Navbar-3&5/navbar1";
import axios from "axios";
import Swal from "sweetalert2";
import HashLoader from "react-spinners/HashLoader";
import { toast } from "react-toastify";
// import { useForm } from "react-hook-form";

const Section7 = () => {
  // const token = localStorage.getItem("uid");
  const navigate = useNavigate();
  const [loadin, setLoadin] = useState(false); // State to track loading //change false for styling -- Musha

  // const {
  //   register,
  //   handleSubmit,
  //   reset,
  //   formState: { errors },
  // } = useForm();

  const [profileImageURL, setProfileImageURL] = useState("/profile.jpg"); // Default fallback image
  function handleSubmitButton() {
    Swal.fire({
      title: "Success",
      text: "Your Profile has been updated",

      icon: "success",
      confirmButtonText: "ok",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/Frame-18");
      }
    });
  }
  let timerInterval;
  let loading = false;
  function handleProgress() {
    Swal.fire({
      title: "Profile has been updating .....",
      html: "",
      timerProgressBar: loading,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        // console.log("I was closed by the timer");
      }
    });
  }
  const t1 = localStorage.getItem("token");
  const [formData, setFormData] = useState({
    // uid: token,
    name: "",
    profile: null,
    email: "",
    gender: "",
    mobileNumber: "",
    companyAddress: "",
    selectedState: "",
    selectedCity: "",
    designation: "",
    pincode: "",
    // language: "English",
  });
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_PORT}/api/updateprofiledata`,
          { type: process.env.REACT_APP_TYPE_EMPLOYEE },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${t1}`,
            },
          }
        );
        const profileData = response.data;
        // console.log(profileData);
        if (!response.data.message) {
          setFormData(profileData); // Update the form data state
          if (profileData.profile) {
            setProfileImageURL(profileData.profile);
          }
        }
      } catch (err) {
        // localStorage.removeItem("token");
        toast.dismiss();
        toast.error("Invalide Credentials");
        navigate("/");
      } finally {
        setLoadin(false); // Set loading to false when fetch completes
      }
    };

    if (t1) {
      fetchProfileData(); // Call the function once the token is available
    } else {
      setLoadin(false);
      toast.warn("Please login to continue");
      navigate("/Desktop-3");
    }
  }, [t1, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Create a local URL for the selected file
      const imageUrl = URL.createObjectURL(file);
      // console.log("Image URL:", imageUrl);
      setProfileImageURL(imageUrl);
      setFormData({
        ...formData,
        [event.target.name]: event.target.files[0], // Only one file
      });
    }
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (value !== null) {
        formDataToSend.append(key, value);
      }
    });
    formDataToSend.append("type", process.env.REACT_APP_TYPE_EMPLOYEE);
    loading = true;
    handleProgress();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PORT}/api/upload`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${t1}`,
          },
        }
      );
      loading = false;
      handleSubmitButton();
      if (response.data.profileUrl) {
        setProfileImageURL(response.data.profileUrl);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };

  const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Delhi",
    "Jammu and Kashmir",
    "Ladakh",
    "Lakshadweep",
    "Puducherry",
  ];

  return (
    <>
      <div>
        {loadin ? (
          <HashLoader
            color="#34a5f3"
            size={60} // Adjust the size as needed
            cssOverride={{
              position: "absolute", // Ensure the loader is positioned relative to the viewport
              top: "50%", // Center vertically
              left: "50%", // Center horizontally
              transform: "translate(-50%, -50%)", // Adjust to truly center the loader
            }}
            loading={true}
          />
        ) : (
          <div>
            <Navemployee />
            {/* <div className="flex justify-center">
              <h2
                className="sm:text-4xl text-2xl w-3/4  sm:ml-96 sm:font-bold font-semibold sm:m-0 sm:mr-14  ml-24  mt-10"
                style={{ color: "#323985" }}
              >
                Edit/ Manage Your Details
              </h2>
            </div> */}

            {/* <form onSubmit={handleSubmitForm}>
              <div className="flex flex-col sm:flex-row mt-5">
                <div className="relative w-full sm:w-1/4 flex justify-center sm:justify-end p-7">
                  <div>
                    <img
                      className="h-[10vh] w-[15vh] sm:ml-38 sm:h-[15vh]  sm:w-[20vh] rounded-full object-cover cursor-pointer"
                      src={profileImageURL} // Adjusted fallback path
                      alt="Company Logo"
                      name="profile"
                      onClick={() =>
                        document.getElementById("fileInput").click()
                      } // Trigger input click on image click
                    />
                    <input
                      id="fileInput"
                      type="file"
                      name="profile"
                      // required
                      onChange={handleFileChange}
                      style={{ display: "none" }} // Hide the file input
                    />
                  </div>
                </div>

                <div className="w-full sm:w-3/4 ">
                  <div className="flex flex-col sm:flex-row sm:justify-start sm:items-start  sm:gap-8 gap-3 mb-2 justify-center items-center">
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      placeholder="Your Full name*"
                      className="placeholder-black bg-[rgba(191,194,228,255)] font-bold text-1xl text-center text-black border rounded-full py-2 w-[70%] sm:w-[20%] md:w-[40%] lg:w-[30%] xl:w-[15%]"
                    />
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Email ID*"
                      className="placeholder-black bg-[rgba(191,194,228,255)] font-bold text-1xl text-center text-black border rounded-full py-2 w-[70%] sm:w-[60%] md:w-[40%] lg:w-[30%] xl:w-[15%]"
                      required
                    />

                    <select
                      name="gender"
                      value={formData.gender}
                      onChange={handleChange}
                      className="placeholder-black bg-[rgba(191,194,228,255)] font-bold text-1xl text-center text-black border rounded-full py-2 w-[70%] sm:w-[60%] md:w-[40%] lg:w-[30%] xl:w-[15%]"
                      required
                    >
                      <option value="" disabled>
                        Select Gender*
                      </option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                  <div className="flex flex-row sm:flex-row sm:justify-start sm:items-start sm:gap-8 gap-3 mb-2 justify-center items-center">
                    <input
                      type="number"
                      name="mobileNumber"
                      value={formData.mobileNumber}
                      onChange={handleChange}
                      placeholder="Mobile Number*"
                      className="placeholder-black bg-[rgba(191,194,228,255)] font-bold text-1xl text-center text-black border rounded-full py-2 w-1/3 sm:w-[60%] md:w-[40%] lg:w-[30%] xl:w-[15%]"
                      required
                      minLength="10"
                      maxLength="10"
                      pattern="\d{10}"
                    />
                    <input
                      type="text"
                      name="companyAddress"
                      value={formData.companyAddress}
                      onChange={handleChange}
                      required
                      placeholder="Company Address"
                      className="placeholder-black bg-[rgba(191,194,228,255)] font-bold text-1xl text-center text-black border rounded-full py-2 w-1/3 sm:w-[60%] md:w-[50%] lg:w-[40%] xl:w-[33%]"
                    />
                  </div>
                  <div className="flex flex-col sm:flex-row sm:justify-start sm:items-start sm:gap-8 gap-3 mb-2 justify-center items-center">
                    <input
                      type="text"
                      name="designation"
                      value={formData.designation}
                      onChange={handleChange}
                      placeholder="Designation*"
                      className="placeholder-black bg-[rgba(191,194,228,255)] font-bold text-1xl text-center text-black border rounded-full py-2 w-[70%] sm:w-[60%] md:w-[40%] lg:w-[30%] xl:w-[15%]"
                      required
                    />
                    <input
                      type="text"
                      name="selectedState"
                      value={formData.selectedState}
                      onChange={handleChange}
                      placeholder="State*"
                      className="placeholder-black bg-[rgba(191,194,228,255)] font-bold text-1xl text-center text-black border rounded-full py-2 w-[70%] sm:w-[60%] md:w-[40%] lg:w-[30%] xl:w-[15%]"
                      required
                    />
                    <input
                      type="text"
                      name="selectedCity"
                      value={formData.selectedCity}
                      onChange={handleChange}
                      placeholder="City*"
                      className="placeholder-black bg-[rgba(191,194,228,255)] font-bold text-1xl text-center text-black border rounded-full py-2 w-[70%] sm:w-[60%] md:w-[40%] lg:w-[30%] xl:w-[15%]"
                      required
                    />
                  </div>
                  <div className="flex flex-col  sm:flex-row sm:justify-start sm:items-start  sm:gap-8  gap-3 mb-2 justify-center items-center sm:ml-56">
                    <input
                      type="number"
                      name="pincode"
                      value={formData.pincode}
                      onChange={handleChange}
                      placeholder="Pincode*"
                      className="placeholder-black bg-[rgba(191,194,228,255)] font-bold text-1xl text-center text-black border rounded-full py-2 w-[70%] sm:w-[60%] md:w-[40%] lg:w-[30%] xl:w-[15%]"
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    className="bg-blue-400 text-white rounded-3xl sm:w-28  py-2 px-4 sm:ml-96 ml-48"
                  >
                    Edit
                  </button>
                </div>
              </div>
            </form> */}

            <section className="py-12">
              <div className="w-11/12 lg:w-10/12 xl:w-9/12 mx-auto   flex flex-col xs:gap-y-8 gap-y-4">
                {/* ---- heading ---- */}

                <h1 className="text-center md:text-4xl xs:text-3xl text-2xl font-bold text-blue-200">
                  Edit/Manage Your Profile
                </h1>

                {/* ----- form ----- */}

                <form
                  onSubmit={handleSubmitForm}
                  className="flex flex-col md:flex-row md:justify-between"
                >
                  {/* ----- profile ----- */}

                  <div className=" md:w-max flex justify-center items-center md:items-start">
                    <img
                      src={profileImageURL}
                      alt="Company Logo"
                      name="profile"
                      onClick={() =>
                        document.getElementById("fileInput").click()
                      } // Trigger input click on image click
                      className="w-32 h-32 rounded-full object-cover"
                    />

                    <input
                      id="fileInput"
                      type="file"
                      name="profile"
                      // required
                      onChange={handleFileChange}
                      style={{ display: "none" }} // Hide the file input
                    />
                  </div>

                  <div className="md:w-[86%] w-full flex flex-col gap-y-6 xmd:text-xl text-lg font-abeezee">
                    <div className="grid sm:grid-cols-3 grid-cols-1 xl:gap-x-12 lg:gap-x-8 gap-x-4 gap-y-6">
                      {/* ---- your name ---- */}

                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        placeholder="Your Full name*"
                        className="bg-purple-200 placeholder-black rounded-[30px] py-2 w-full px-4 outline-none focus:outline-blue-100 "
                      />

                      {/* ----- email ---- */}

                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Email ID*"
                        className="bg-purple-200 placeholder-black rounded-[30px] py-2 w-full px-4 outline-none focus:outline-blue-100 "
                        required
                      />

                      {/* ------ gender ----- */}

                      <select
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                        className="bg-purple-200 placeholder-black rounded-[30px] py-2 w-full px-4 outline-none focus:outline-blue-100"
                        required
                      >
                        <option value="" disabled>
                          Select Gender*
                        </option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </select>
                    </div>

                    <div className="grid sm:grid-cols-3 grid-cols-1 xl:gap-x-12 lg:gap-x-8 gap-x-4 gap-y-6">
                      {/* ------ mobile number ----- */}
                      <input
                        type="text"
                        name="mobileNumber"
                        value={formData.mobileNumber}
                        onChange={handleChange}
                        placeholder="Mobile Number*"
                        className="bg-purple-200 placeholder-black rounded-[30px] py-2 w-full px-4 outline-none focus:outline-blue-100 "
                        required
                        minLength="10"
                        maxLength="10"
                        pattern="\d{10}"
                      />

                      {/* ----- company address ----- */}

                      <input
                        type="text"
                        name="companyAddress"
                        value={formData.companyAddress}
                        onChange={handleChange}
                        placeholder="Company Address*"
                        className="sm:col-span-2 bg-purple-200 placeholder-black rounded-[30px] py-2 w-full px-4 outline-none focus:outline-blue-100"
                        required
                      />
                    </div>

                    <div className="grid xs:grid-cols-3 grid-cols-1 xl:gap-x-12 lg:gap-x-8 gap-x-4 gap-y-6">
                      {/* ------ desgination ----- */}

                      <input
                        type="text"
                        name="designation"
                        value={formData.designation}
                        onChange={handleChange}
                        placeholder="Designation*"
                        className="bg-purple-200 placeholder-black rounded-[30px] py-2 w-full px-4 outline-none focus:outline-blue-100"
                        required
                      />

                      {/* ----- states ----- */}

                      <select
                        name="selectedState"
                        value={formData.selectedState}
                        onChange={handleChange}
                        className="bg-purple-200 placeholder-black rounded-[30px] py-2 w-full px-4 outline-none focus:outline-blue-100"
                        required
                      >
                        <option value="" key={0}>
                          State*
                        </option>
                        {states.map((state, index) => (
                          <option key={index} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>

                      {/* ------- city ------ */}

                      <input
                        type="text"
                        name="selectedCity"
                        value={formData.selectedCity}
                        onChange={handleChange}
                        placeholder="City*"
                        className="bg-purple-200 placeholder-black rounded-[30px] py-2 w-full px-4 outline-none focus:outline-blue-100"
                        required
                      />
                    </div>

                    <div className="flex  xs:justify-center ">
                      <input
                        type="text"
                        name="pincode"
                        value={formData.pincode}
                        onChange={handleChange}
                        placeholder="Pincode*"
                        className="bg-purple-200 placeholder-black rounded-[30px] py-2  px-4 outline-none focus:outline-blue-100 w-full xs:w-max"
                        required
                      />
                    </div>

                    {/* ---- submit / edit button --- */}
                    <div className="font-inter flex justify-end">
                      <button
                        type="submit"
                        className="bg-blue-200 xs:mt-0 mt-2 text-white px-16 py-2 rounded-[140px] font-semibold transition-all duuration-300 cursor-pointer hover:scale-105"
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        )}
      </div>
    </>
  );
};

export default Section7;
