import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useState, useEffect } from "react";

const Frame3 = () => {
  const [showAbout, setShowAbout] = useState(false);

  useEffect(() => {
    const intervalOfAbout = setInterval(() => {
      setShowAbout((prev) => !prev);
    }, 5000);

    return () => clearInterval(intervalOfAbout);
  }, []);

  return (
    //Mushafiya-> Small the text size and lower the padding and also width of container

    <section
      className=" py-8 sm:py-12  font-inter text-white bg-no-repeat bg-center bg-cover "
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/backgroundremove1.png)`,
      }}
      id="about-us"
    >
      {/* -------- container ------- */}

      <div className="w-10/12 lg:w-[70%] sm:pr-6  mx-auto  text-lg">
        {/* --------- for desktop screen --------- */}
        <div className="hidden sm:flex flex-col gap-y-6">
          {/* ------- About us ------ */}

          <div className="flex flex-col gap-y-8">
            {/* ------- heading ---- */}

            <div className="flex flex-col gap-y-2 items-center text-center  text-2xl">
              <h1 className="font-bold">About Us</h1>
              <h1 className="font-semibold">
                Empowering Careers, Connecting Futures!!{" "}
              </h1>
            </div>

            {/* ------ desc -------- */}

            <p className=" px-4 md:text-left text-center">
              Welcome to Hirers, a dynamic platform committed to reshaping the
              employment landscape in India. At Hirers, we believe in the
              transformative power of meaningful employment, where careers are
              not just jobs but paths to personal and professional fulfillment.
            </p>
          </div>

          {/* -------- Our Mission -------- */}
          <div className="flex flex-col gap-y-4">
            {/* ------- heading ---- */}

            <div className="flex flex-col gap-y-2 items-center text-center text-2xl">
              <h1 className="font-semibold">#Our Mission</h1>
            </div>

            {/* ------ desc -------- */}

            <p className=" px-4 md:text-left text-center">
              "To revolutionize the job search and recruitment process in India
              by connecting job seekers with employers, fostering inclusive
              growth, and contributing to sustainable development."
            </p>
          </div>

          {/* -------- Our Vision ----- */}

          <div className="flex flex-col gap-y-4">
            {/* ------- heading ---- */}

            <div className="flex flex-col gap-y-2 items-center text-center  text-2xl">
              <h1 className="font-semibold">#Our Vision</h1>
            </div>

            {/* ------ desc -------- */}

            <div className=" px-4 flex flex-col gap-y-4 md:text-left text-center">
              <p>
                "To create a future where every individual, regardless of
                background, has access to meaningful employment and to be a
                catalyst for positive change and development in India."
              </p>
              <p>
                Hirers is more than a job portal; it's a movement dedicated to
                building careers, fostering sustainability, and contributing to
                the evolution of a prosperous India. Whether you're a job seeker
                ready to embark on a new journey or an employer seeking
                exceptional talent, Hirers is your gateway to opportunities that
                matter.
              </p>
            </div>
          </div>
        </div>

        {/* --------- for mobile screen ----------- */}

        <div className="flex items-center sm:hidden min-h-[330px] ">
          <AnimatePresence mode="wait">
            {showAbout ? (
              <motion.div
                key="about"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
                className="flex flex-col gap-y-8"
              >
                {/* ------- heading ---- */}

                <div className="flex flex-col  items-center text-center md:text-4xl ">
                  <h1 className="font-semibold text-lg">#About Us</h1>
                  <h1 className="font-normal text-base">
                    Empowering Careers, Connecting Futures!!{" "}
                  </h1>
                </div>

                {/* ------ desc -------- */}

                <p className=" text-sm text-center   ">
                  Welcome to Hirers, a dynamic platform committed to reshaping
                  the employment landscape in India. At Hirers, we believe in
                  the transformative power of meaningful employment, where
                  careers are not just jobs but paths to personal and
                  professional fulfillment.
                </p>
              </motion.div>
            ) : (
              <motion.div
                key="mission"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
                className="flex flex-col gap-y-6"
              >
                {/* -------- Our Mission -------- */}
                <div className="flex flex-col gap-y-2">
                  {/* ------- heading ---- */}

                  <div className="flex flex-col  items-center text-center md:text-3xl sm:text-2xl text-lg">
                    <h1 className="font-semibold">#Our Mission</h1>
                  </div>

                  {/* ------ desc -------- */}

                  <p className=" text-sm text-center  ">
                    "To revolutionize the job search and recruitment process in
                    India by connecting job seekers with employers, fostering
                    inclusive growth, and contributing to sustainable
                    development."
                  </p>
                </div>

                {/* -------- Our Mission ----- */}

                <div className="flex flex-col gap-y-2">
                  {/* ------- heading ---- */}

                  <div className="flex flex-col  items-center text-center md:text-3xl sm:text-2xl text-lg">
                    <h1 className="font-semibold">#Our Vision</h1>
                  </div>

                  {/* ------ desc -------- */}

                  <p className="text-sm text-center  ">
                    "To create a future where every individual, regardless of
                    background, has access to meaningful employment and to be a
                    catalyst for positive change and development in India."
                  </p>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </section>
  );
};

export default Frame3;
