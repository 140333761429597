import profile from './job_seekers.png'


export const jobsData = {

    name: "Rohit Sharma",
    designation: "Manager",
    jobs: [
        {
            id:1,
            companyName: 'BPO Customer Care',
            jobTitle: "Pharmacist",
            postedOn: '20-11-24',
            expireOn: '30-11-24',
            jobStatus: 'Active',
            responses: [
                {
                    id: 1.1,
                    image: profile,
                    name: "Shreya Singh",
                    age: 20,
                    gender: "Female",
                    address: "Mumbai, Maharashtra",
                    educationQualification: "graduated",
                    experience: "fresher",
                    otherSkill: "Team management, leadership",
                    mobileNumber: "6549871235",
                    languagesKnown: "Hindi, English",
                    workExperience: "0",
                    email: "shreya123@gmail.com",
                    appliedOn: "26-11-2024",
                    status: ""
                },
                {
                    id: 1.2,
                    image: "",
                    name: "Shreya Singh",
                    age: 20,
                    gender: "Female",
                    address: "Mumbai, Maharashtra",
                    educationQualification: "graduated",
                    experience: "fresher",
                    otherSkill: "Team management, leadership",
                    mobileNumber: "6549871235",
                    languagesKnown: "Hindi, English",
                    workExperience: "0",
                    email: "shreya123@gmail.com",
                    appliedOn: "24-11-2024",
                    status: "shortlisted"
                },
                {
                    id: 1.3,
                    image: profile,
                    name: "Shreya Singh",
                    age: 20,
                    gender: "Female",
                    address: "Mumbai, Maharashtra",
                    educationQualification: "graduated",
                    experience: "fresher",
                    otherSkill: "Team management, leadership",
                    mobileNumber: "6549871235",
                    languagesKnown: "Hindi, English",
                    workExperience: "0",
                    email: "shreya123@gmail.com",
                    appliedOn: "22-11-2024",
                      status: "rejected"
                },
                {
                    id: 1.4,
                    image: profile,
                    name: "Shreya Singh",
                    age: 20,
                    gender: "Female",
                    address: "Mumbai, Maharashtra",
                    educationQualification: "graduated",
                    experience: "fresher",
                    otherSkill: "Team management, leadership",
                    mobileNumber: "6549871235",
                    languagesKnown: "Hindi, English",
                    workExperience: "0",
                    email: "shreya123@gmail.com",
                    appliedOn: "18-11-2024",
                    status: "",
                },
                {
                    id: 1.5,
                    image: profile,
                    name: "Shreya Singh",
                    age: 20,
                    gender: "Female",
                    address: "Mumbai, Maharashtra",
                    educationQualification: "graduated",
                    experience: "fresher",
                    otherSkill: "Team management, leadership",
                    mobileNumber: "6549871235",
                    languagesKnown: "Hindi, English",
                    workExperience: "0",
                    email: "shreya123@gmail.com",
                    appliedOn: "25-11-2024",
                    status: "",
                },
                {
                    id: 1.6,
                    image: profile,
                    name: "Shreya Singh",
                    age: 20,
                    gender: "Female",
                    address: "Mumbai, Maharashtra",
                    educationQualification: "graduated",
                    experience: "fresher",
                    otherSkill: "Team management, leadership",
                    mobileNumber: "6549871235",
                    languagesKnown: "Hindi, English",
                    workExperience: "0",
                    email: "shreya123@gmail.com",
                    appliedOn: "22-11-2024",
                    status: "",
                },
            ],
            shortlisted: 2,
            rejected: 4,


        },
        {
            id:2,
            companyName: 'BPO Service Center',
            jobTitle: "Pharmacist",
            postedOn: '18-10-24',
            expireOn: '22-10-24',
            jobStatus: 'Active',
            responses: [
                {
                    id: 2.1,
                    image: profile,
                    name: "Shreya Singh",
                    age: 20,
                    gender: "Female",
                    address: "Mumbai, Maharashtra",
                    educationQualification: "graduated",
                    experience: "fresher",
                    otherSkill: "Team management, leadership",
                    mobileNumber: "6549871235",
                    languagesKnown: "Hindi, English",
                    workExperience: "0yr",
                    email: "shreya123@gmail.com",
                    appliedOn: "22-11-24",
                },
                {
                    id: 2.2,
                    image: profile,
                    name: "Shreya Singh",
                    age: 20,
                    gender: "Female",
                    address: "Mumbai, Maharashtra",
                    educationQualification: "graduated",
                    experience: "fresher",
                    otherSkill: "Team management, leadership",
                    mobileNumber: "6549871235",
                    languagesKnown: "Hindi, English",
                    workExperience: "0yr",
                    email: "shreya123@gmail.com",
                    appliedOn: "22-11-24",
                },
                {
                    id: 2.3,
                    image: profile,
                    name: "Shreya Singh",
                    age: 20,
                    gender: "Female",
                    address: "Mumbai, Maharashtra",
                    educationQualification: "graduated",
                    experience: "fresher",
                    otherSkill: "Team management, leadership",
                    mobileNumber: "6549871235",
                    languagesKnown: "Hindi, English",
                    workExperience: "0yr",
                    email: "shreya123@gmail.com",
                    appliedOn: "22-11-24",
                },
                {
                    id: 2.4,
                    image: profile,
                    name: "Shreya Singh",
                    age: 20,
                    gender: "Female",
                    address: "Mumbai, Maharashtra",
                    educationQualification: "graduated",
                    experience: "fresher",
                    otherSkill: "Team management, leadership",
                    mobileNumber: "6549871235",
                    languagesKnown: "Hindi, English",
                    workExperience: "0yr",
                    email: "shreya123@gmail.com",
                    appliedOn: "22-11-24",
                },
                {
                    id: 2.5,
                    image: profile,
                    name: "Shreya Singh",
                    age: 20,
                    gender: "Female",
                    address: "Mumbai, Maharashtra",
                    educationQualification: "graduated",
                    experience: "fresher",
                    otherSkill: "Team management, leadership",
                    mobileNumber: "6549871235",
                    languagesKnown: "Hindi, English",
                    workExperience: "0yr",
                    email: "shreya123@gmail.com",
                    appliedOn: "22-11-24",
                },
                {
                    id: 2.6,
                    image: profile,
                    name: "Shreya Singh",
                    age: 20,
                    gender: "Female",
                    address: "Mumbai, Maharashtra",
                    educationQualification: "graduated",
                    experience: "fresher",
                    otherSkill: "Team management, leadership",
                    mobileNumber: "6549871235",
                    languagesKnown: "Hindi, English",
                    workExperience: "0yr",
                    email: "shreya123@gmail.com",
                    appliedOn: "22-11-24",
                },
            ],
            shortlisted: 10,
            rejected: 8,

        },
        {
            id:3,
            companyName: 'BPO Centre',
            jobTitle: "Pharmacist",
            postedOn: '12-09-24',
            expireOn: '31-09-24',
            jobStatus: 'In-Active',
            responses: [
                {
                    id: 3.1,
                    image: profile,
                    name: "Shreya Singh",
                    age: 20,
                    gender: "Female",
                    address: "Mumbai, Maharashtra",
                    educationQualification: "graduated",
                    experience: "fresher",
                    otherSkill: "Team management, leadership",
                    mobileNumber: "6549871235",
                    languagesKnown: "Hindi, English",
                    workExperience: "0yr",
                    email: "shreya123@gmail.com",
                    appliedOn: "22-11-24",
                },
                {
                    id: 3.2,
                    image: profile,
                    name: "Shreya Singh",
                    age: 20,
                    gender: "Female",
                    address: "Mumbai, Maharashtra",
                    educationQualification: "graduated",
                    experience: "fresher",
                    otherSkill: "Team management, leadership",
                    mobileNumber: "6549871235",
                    languagesKnown: "Hindi, English",
                    workExperience: "0yr",
                    email: "shreya123@gmail.com",
                    appliedOn: "22-11-24",
                },
                {
                    id: 3.3,
                    image: profile,
                    name: "Shreya Singh",
                    age: 20,
                    gender: "Female",
                    address: "Mumbai, Maharashtra",
                    educationQualification: "graduated",
                    experience: "fresher",
                    otherSkill: "Team management, leadership",
                    mobileNumber: "6549871235",
                    languagesKnown: "Hindi, English",
                    workExperience: "0yr",
                    email: "shreya123@gmail.com",
                    appliedOn: "22-11-24",
                },
                {
                    id: 3.4,
                    image: profile,
                    name: "Shreya Singh",
                    age: 20,
                    gender: "Female",
                    address: "Mumbai, Maharashtra",
                    educationQualification: "graduated",
                    experience: "fresher",
                    otherSkill: "Team management, leadership",
                    mobileNumber: "6549871235",
                    languagesKnown: "Hindi, English",
                    workExperience: "0yr",
                    email: "shreya123@gmail.com",
                    appliedOn: "22-11-24",
                },
                {
                    id: 3.5,
                    image: profile,
                    name: "Shreya Singh",
                    age: 20,
                    gender: "Female",
                    address: "Mumbai, Maharashtra",
                    educationQualification: "graduated",
                    experience: "fresher",
                    otherSkill: "Team management, leadership",
                    mobileNumber: "6549871235",
                    languagesKnown: "Hindi, English",
                    workExperience: "0yr",
                    email: "shreya123@gmail.com",
                    appliedOn: "22-11-24",
                },
                {
                    id: 3.6,
                    image: profile,
                    name: "Shreya Singh",
                    age: 20,
                    gender: "Female",
                    address: "Mumbai, Maharashtra",
                    educationQualification: "graduated",
                    experience: "fresher",
                    otherSkill: "Team management, leadership",
                    mobileNumber: "6549871235",
                    languagesKnown: "Hindi, English",
                    workExperience: "0yr",
                    email: "shreya123@gmail.com",
                    appliedOn: "22-11-24",
                },
            ],
            shortlisted: 9,
            rejected: 3,

        },
    ]

}