import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";
import axios from "axios";
import { toast } from "react-toastify";
import Navemployee from "../Sections/Navbar-3&5/Navbar_employee";
function Frame12() {
  const t1 = localStorage.getItem("token");
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [jobData, setJobData] = useState(null);
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);

  const handledoc = (jobId) => {
    navigate(`/Section-2/${jobId}`); // Navigate to Section3 with jobId
  };

  // const jobData = {
  //   jobTitle: "Software Engineer",
  //   companyName: "TechCorp",
  //   companyAddress: "123 Silicon Valley",
  //   jobLocation: "San Francisco, CA",
  //   salary: "$120,000",
  //   educationQualification: "Bachelor's in Computer Science and dbh dhuysb",
  //   experienceLevel: "3-5 years",
  //   communicationSkill: "Excellent",
  //   Description:
  //     "Develop and maintain web applications using modern frameworks. ",
  //   Gender: "Any",
  //   similarJobs: [
  //     "Frontend Developer - San Francisco, CA",
  //     "Backend Developer - Seattle, WA",
  //   ],
  // };

  useEffect(() => {
    const fetchJobData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_PORT}/api/jobs/${jobId}`,
          {
            headers: {
              Authorization: `Bearer ${t1}`, // Ensure t1 contains your actual token
            },
          }
        );

        const data = response.data; // Access the data directly from the Axios response
        // console.log(data);
        data.id = jobId; // Assign jobId to the data
        setJobData(data); // Update the state with the fetched job data
      } catch (err) {
        // console.error("Error fetching job data:", err);
        // localStorage.removeItem("token");
        toast.error("Invalide Credentials");
        navigate("/");
      } finally {
        setLoading(false);
      }
    };

    fetchJobData();
  }, [jobId, t1, jobData, navigate]);

  return (
    <>
      <div className="h-full w-full ">
        {loading ? (
          <HashLoader
            color="#34a5f3"
            size={60} // Adjust the size as needed
            cssOverride={{
              position: "absolute", // Ensure the loader is positioned relative to the viewport
              top: "50%", // Center vertically
              left: "50%", // Center horizontally
              transform: "translate(-50%, -50%)", // Adjust to truly center the loader
            }}
            loading={true}
          />
        ) : (
          <div className="h-full w-full  ">
            <Navemployee />
            <div className="w-full  bg-white flex flex-col font-ABeeZee">
              {/* Header Section */}
              <div className="w-full sm:text-white p-3 flex sm:flex-row sm:bg-primary-blue sm:mt-0 mt-2">
                {/* Company Logo (Image) */}
                <div className="w-12 h-12 sm:hidden flex justify-center items-center rounded-full overflow-hidden border">
                  <img
                    src="/profile.jpg"
                    alt={`${jobData.companyName} logo`}
                    className="w-full h-full object-cover sm:rounded-full"
                  />
                </div>

                {/* Job Information */}
                <div className="w-3/4 sm:w-3/4 flex flex-col font-abeezee ml-3 sm:ml-8 sm:text-lg text-base sm:font-normal font-medium">
                  <h2 className="text-lg sm:text-2xl md:text-3xl sm:mb-2 ">
                    {jobData.jobTitle}
                  </h2>
                  <p className="mb-2 text-secondary-blue sm:text-white">
                    {jobData.companyName}
                  </p>

                  {/* Address and Location with Small Rounded Images */}
                  <div className="w-full flex items-center mb-2 xs:mt-3 sm:mt-0 ">
                    <div className="w-9 h-9 sm:hidden flex justify-center items-center mr-3">
                      <img
                        src="/location.png" // Replace with appropriate icon/image
                        alt="Location Icon"
                        className="w-full h-90% object-cover "
                      />
                    </div>
                    <p className="sm:mb-2 text-base sm:text-lg">
                      {jobData.companyAddress}, {jobData.jobLocation}
                    </p>
                  </div>

                  {/* Salary with Small Rounded Icon */}
                  <div className="w-full flex items-center mb-2">
                    <div className="w-8 h-8  sm:hidden flex justify-center items-center mr-3">
                      <img
                        src="/salary_icon.png" // Replace with appropriate icon/image
                        alt="Salary Icon"
                        className="w-full h-full object-cover "
                      />
                    </div>
                    <p className="text-md sm:text-xl">{jobData.salary}</p>
                  </div>

                  <button
                    className="w-36 bg-white text-black mt-2 py-[6px] px-2 hidden sm:block rounded-lg text-base"
                    onClick={() => handledoc(jobData.id)}
                  >
                    Category
                  </button>
                </div>

                {/* Apply Now Button */}
                <div className="sm:flex items-start justify-end w-full mt-4 sm:mt-0 hidden sm:mr-8">
                  <button
                    className="bg-white text-secondary-blue py-[6px] px-6 rounded-xl text-base font-semibold"
                    onClick={() => handledoc(jobData.id)}
                  >
                    Apply Now
                  </button>
                </div>
              </div>

              <div class="  bg-blue-50 p-3 rounded-2xl shadow-md  flex  justify-between sm:hidden mt-4 gap-2 m-2 text-sm">
                <button
                  href="#desc"
                  class="px-4 py-1 bg-white text-black rounded-2xl "
                >
                  Job Details
                </button>
                <button class="px-4 py-1 bg-white text-black rounded-2xl ">
                  Job Description
                </button>
                <button class="px-4 py-1  bg-white text-black rounded-2xl">
                  Similar Jobs
                </button>
              </div>

              <div className="p-4 flex-1 md:flex-2 md:px-8 md:py-6 flex flex-col justify-around font-abeezee">
                <div className="flex flex-col md:flex-row justify-between  ">
                  <div className="flex-1  mb-6  sm:bg-transparent  rounded-3xl m-2 sm:ml-10 bg-blue-10 sm:bg-white sm:px-0 px-2">
                    <div className=" w-full sm:mt-0 sm:mb-0 mt-2 mb-2">
                      <h3 className=" sm:text-3xl text-xl  font-abeezee text-center sm:text-left font-semibold sm:font-normal">
                        Job Details
                      </h3>
                    </div>

                    <div className="w-full  grid sm:grid-cols-2 gap-2  md:text-sm mt-2 sm:w-[65vw]">
                      <div className="w-full flex flex-col ">
                        <div className="sm:m-2 mt-2 sm:text-lg sm:text-black-10 text-blue-800 text-lg sm:block flex justify-start sm:ml-0 ml-8">
                          <p className="">Education</p>
                        </div>
                        <div className="sm:m-2 text-base sm:text-xl sm:block flex justify-start sm:ml-0 ml-8 ">
                          <p className="">{jobData.educationQualification}</p>
                        </div>{" "}
                        {/* Updated */}
                        <div className="sm:m-2 mt-4 sm:text-lg sm:text-black-10 text-blue-800 text-lg sm:block flex justify-start sm:ml-0 ml-8 sm:mt-0">
                          <p className="">Experience</p>
                        </div>
                        <div className="sm:m-2 text-base sm:text-xl sm:block flex justify-start sm:ml-0 ml-8 ">
                          <p className="">{jobData.experienceLevel}</p>
                        </div>{" "}
                        <div className="sm:m-2 mt-4 sm:text-lg sm:text-black-10 text-blue-800 text-lg sm:block flex justify-start sm:ml-0 ml-8 sm:mt-0">
                          <p className=""> Address</p>
                        </div>
                        <div className="sm:m-2 text-base sm:text-xl sm:block flex justify-start sm:ml-0 ml-8 ">
                          <p className="">
                            {jobData.companyAddress},{jobData.jobLocation}
                          </p>
                        </div>{" "}
                        {/* Updated */}
                      </div>
                      <div className="">
                        <div className="sm:m-2 mt-4 sm:text-lg sm:text-black-10 text-blue-800 text-lg sm:block flex justify-start sm:ml-0 ml-8 sm:mt-0">
                          <p className=""> Gender</p>
                        </div>
                        <div className="sm:m-2 text-lg sm:text-xl sm:block flex justify-start sm:ml-0 ml-8 ">
                          <p className="">
                            {jobData.Gender || "Not mentioned"}
                          </p>
                        </div>{" "}
                        {/* Ensure this key exists */}
                        <div className="sm:m-2 mt-4 sm:text-lg sm:text-black-10 text-blue-800 text-lg sm:block flex justify-start sm:ml-0 ml-8 sm:mt-0">
                          <p className=""> Communication Skills</p>
                        </div>
                        <div className="sm:m-2 text-lg sm:text-xl sm:block flex justify-start sm:ml-0 ml-8 sm:mb-0 mb-6 ">
                          <p className=""> {jobData.communicationSkill}</p>
                        </div>{" "}
                        {/* Updated */}
                      </div>
                    </div>
                  </div>

                  <div
                    id="desc"
                    className=" m-2 block sm:hidden  font-abeezee text-center bg-blue-10 rounded-xl"
                  >
                    <h3 className="  text-xl font-semibold mt-2">
                      Job Description
                    </h3>
                    <p className="text-base mt-2 mb-4 text-blue-800">
                      {jobData.Description || "No description available."}
                    </p>
                  </div>
                  <div class="bg-white p-4 rounded-lg flex flex-row justify-between items-center  sm:hidden ">
                    <button class="bg-primary-blue text-white py-2 px-4 rounded-xl hover:bg-blue-600 ">
                      Share
                    </button>
                    <button
                      class="bg-primary-blue  text-white py-2 px-6 rounded-xl hover:bg-blue-600 sm:ml-28"
                      onClick={() => handledoc(jobData.id)}
                    >
                      Apply Now
                    </button>
                  </div>
                  <div className=" flex-1">
                    <h3 className="md:text-2xl text-xl text-center font-abeezee mt-4">
                      Similar Jobs
                    </h3>
                    <div className="mt-2 text-xs md:text-sm text-gray-500 space-y-2 text-center">
                      <div>
                        {/*  */}
                        <button className="  text-white px-6 py-3 rounded-3xl text-lg">
                          {jobData.similarJobs?.length > 0 ? (
                            jobData.similarJobs.map((job, index) => (
                              <div key={index} className="flex justify-between">
                                <button className=" w-52 md:w-60 relative text-start text-black-10 px-4 py-2 rounded-3xl  focus:outline-none focus:ring-2  text-base sm:text-xl  overflow-hidden truncate mb-1">
                                  {job}
                                  <span className="absolute right-2">&gt;</span>
                                </button>
                              </div>
                            ))
                          ) : (
                            <div className="bg-secondary-blue px-4 py-2 rounded-xl text-base">
                              No similar jobs found
                            </div>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="hidden sm:block ">
                  <h3 className=" sm:text-3xl text-xl sm:ml-1 ml-28">
                    Job Description
                  </h3>
                  <p className="mt-2 text-lg sm:text-xl text-black-10 ml-5">
                    {jobData.Description || "No description available."}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Frame12;
