// NotFound.js
import React from "react";
import { useNavigate } from "react-router-dom";
import "./NotFound.css"; // Assuming the CSS is in NotFound.css

const NotFound = () => {
  const navigate = useNavigate(); // useNavigate hook to programmatically navigate

  const goHome = () => {
    navigate("/"); // Navigate to home route
  };

  return (
    <section className="page_404">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="col-sm-10 col-sm-offset-1 text-center">
              <div className="four_zero_four_bg">
                <h1 className="text-center">404</h1>
              </div>

              <div className="contant_box_404">
                <h3 className="h2">Look like you're lost</h3>
                <p>The page you are looking for is not available!</p>

                {/* Add onClick event to trigger the navigation */}
                <button className="link_404" onClick={goHome}>
                  Go to Home
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
