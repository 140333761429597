import React, { useEffect, useState } from "react";
import HorizontalScroll from "../Desktop1/companys_scroll";
import { auth, googleProvider, facebookProvider } from "../firebase";
import { signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";
// import Navbar2 from "../Sections/Navbar-3&5/Navbar2";
import axios from "axios";
// import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { ColorRing } from "react-loader-spinner";
import "react-toastify/dist/ReactToastify.css"; // Import CSS for Toastify
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const CompanySection = () => {
  const navigate = useNavigate();
  // const companies = [
  //   "Zomato_logo.png",
  //   "rapido.png",
  //   "paytm.png",
  //   "uber.png",
  //   "samsung.png",
  //   "techmahindra.png",
  //   "swiggy.png",
  //   "daikin.png",
  //   "airtel.jpg",
  //   "webvi.png",
  //   "tp.png",
  //   "policybazaar.jpeg",
  //   "moneylink.png",
  //   "ambar skytech.png",
  // ];

  const token = localStorage.getItem("token");
  const type = localStorage.getItem("type");

  useEffect(() => {
    console.log("Token:", token);
    if (token && type === "users") {
      navigate("/Frame-10");
    }
  }, [token, type, navigate]);

  const handleGoogleSignIn = async () => {
    setLoader(true);

    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      // testing code
      const idToke = await user.getIdTokenResult();
      if (!idToke.claims.role) {
        // If the user is new (no role), set their role
        console.log("new user", process.env.REACT_APP_TYPE_EMPLOYER);
        await setRoleOnBackend(user.uid, process.env.REACT_APP_TYPE_EMPLOYER); // Set role as "employee" for new users
        await getTokenFromServer(user.uid, process.env.REACT_APP_TYPE_EMPLOYER); // Reuse the external function
        toast.success("Google Sign-In successful!");
        navigate("/Frame-10");
      } else {
        const role = idToke.claims.role;
        if (role === process.env.REACT_APP_TYPE_EMPLOYER) {
          await getTokenFromServer(
            user.uid,
            process.env.REACT_APP_TYPE_EMPLOYER
          );
          toast.success("Google Sign-In successful!");
          navigate("/Frame-10");
        } else if (
          role === process.env.REACT_APP_TYPE_EMPLOYEE ||
          role === process.env.REACT_APP_TYPE_ADMIN
        ) {
          toast.error("Access Denied for this email");
        } else {
          toast.error("Invalid role detected. Please contact support.");
        }
      }
    } catch (error) {
      console.error("Error during Google Sign-In: ", error);
      toast.error("Google Sign-In failed. Please try again.");
    } finally {
      setLoader(false);
    }
  };

  const getTokenFromServer = async (uid, role) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PORT}/generateToken`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ uid, type: role }), // Pass UID and role as necessary data
        }
      );

      if (!response.ok) {
        throw new Error("Failed to retrieve token");
      }
      const data = await response.json();
      localStorage.setItem("token", data.token);
    } catch (error) {
      console.error("Error fetching token:", error);
      throw error; // Rethrow to be caught in the outer try-catch
    }
  };

  const setRoleOnBackend = async (uid, role) => {
    try {
      await fetch(`${process.env.REACT_APP_PORT}/setRole`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ uid, role }),
      });
    } catch (error) {
      console.error("Error setting role:", error);
    }
  };

  const handleFacebookSignIn = async () => {
    setLoader(true);
    try {
      // console.log("Facebook Sign-In initiated");
      const result = await signInWithPopup(auth, facebookProvider);
      const user = result.user;
      // console.log("User Info from Facebook:", user);
      const response = await fetch(
        `${process.env.REACT_APP_PORT}/generateToken`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ uid: user.uid, type: "users" }), // Pass UID or any other necessary data
        }
      );
      const data = await response.json();
      localStorage.setItem("token", data.token);
      // console.log("User Info from Facebook:", user);
      toast.success("Facebook Sign-In successful!");
    } catch (error) {
      console.error("Error during Facebook login:", error.message);
      toast.error("Facebook Sign-In failed. Please try again.");
    } finally {
      setLoader(false);
    }
  };

  // Ensure this matches the server's key

  const [countryCode, setCountryCode] = useState("+91");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleVerifyOtp = async (e) => {
    setLoader(true);
    e.preventDefault();

    if (!otp) {
      toast.error("Please enter the OTP");
      setLoader(false);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PORT}/verify-otp`,
        {
          phoneNumber: countryCode + phoneNumber,
          otp: otp,
        }
      );
      // console.log(response.data);

      if (response.data.message === "OTP verified successfully") {
        toast.success("OTP verified successfully!");
        // const decryptedPhone = decryptPhoneNumber(response.data.phoneNumber);
        // console.log(decryptedPhone);
        const responso = await fetch(
          `${process.env.REACT_APP_PORT}/generateToken`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              uid: response.data.phoneNumber,
              type: "users",
            }), // Pass UID or any other necessary data
          }
        );
        const data = await responso.json();
        localStorage.setItem("token", data.token);
        localStorage.setItem("type", "users");
        navigate("/Frame-10");
      } else {
        toast.error("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("There was an error verifying the OTP.");
    } finally {
      setLoader(false);
    }
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    setLoader(true);
    if (!phoneNumber || phoneNumber.length !== 10) {
      toast.error("Please enter a valid 10-digit phone number");
      setLoader(false);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PORT}/send-otp`,
        {
          phoneNumber: countryCode + phoneNumber,
        }
      );

      if (response.data.message === "OTP sent successfully") {
        toast.success("OTP sent successfully!");
        setOtpSent(true);
      } else {
        toast.error("Failed to send OTP.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error("There was an error sending the OTP.");
    } finally {
      setLoader(false);
    }
  };

  return (
    <section className="py-16 w-full font-inter">
      {/* ------------ container ---------------- */}

      <div className="xl:w-10/12 w-11/12 mx-auto  flex flex-col ">
        {/* -------- 1st section -------- */}
        <div className="flex xmd:flex-row flex-col  xl:gap-x-8 gap-x-6 gap-y-12 justify-between ">
          {/* ----- left part ---- */}
          <div className="xl:w-[46%] xmd:w-[43%] md:w-[80%] sm:w-[90%] mx-auto flex flex-col gap-y-2 pr-6 xs:pr-0">
            <h1 className="xl:text-6xl lg:text-5xl text-3xl font-semibold xl:w-[80%] w-[90%] xl:leading-[77px] lg:leading-[62px]  leading-[56px] text-blue-200 ">
              Get Your Job In 48 Hours!!
            </h1>
            <p className="text-xl font-semibold ">
              Log in now and get your job with Hirers.
            </p>
          </div>

          {/* ------- right part ----- */}
          <div className=" bg-blue-50 xl:w-[45%] xmd:w-[50%] md:w-[70%] sm:w-[90%] w-full  mx-auto   sm:py-5 py-4 xl:px-8 sm:px-2 xs:px-4 px-4  rounded-[32px] flex flex-col gap-y-6 items-center sm:border-0 border-2">
            <h2 className="xl:text-2xl xs:text-lg text-lg font-semibold text-center">
              Candidate LogIn/SignUp
            </h2>
            <form
              onSubmit={otpSent ? handleVerifyOtp : handleSendOtp}
              className="flex flex-col gap-y-4  justify-center items-center "
            >
              <div className=" xs:w-full w-[50%] flex  dxs:flex-nowrap flex-wrap xl:gap-x-8 xsm:gap-x-4 gap-x-2 justify-center  items-center  sm:text-lg text-base">
                <input
                  type="text"
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                  placeholder="+91"
                  className="py-3 px-2 sm:w-[60px] xsm:w-[48px] w-[44px] rounded-lg text-black placeholder-black font-medium"
                />
                <input
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Mobile Number"
                  className="py-3 px-4 rounded-lg placeholder-black text-black font-medium"
                />
              </div>
              {otpSent && (
                <div className="xs:w-full w-[100%] flex justify-end items-center xl:gap-x-8 xsm:gap-x-4 gap-x-2 ">
                  <input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="Enter OTP"
                    className="py-3 px-4 w-[100%] rounded-lg placeholder-black text-black font-medium "
                  />
                </div>
              )}

              <button
                type="submit"
                className=" xs:w-full w-[50%] bg-blue-200 text-white flex items-center justify-center py-2 rounded-lg xl:text-xl xsm:text-lg text-base transition-all duration-300 hover:bg-blue-300 hover:scale-105 px-2 "
                style={{ background: "#393285" }}
                disabled={loader}
              >
                {loader ? (
                  <div
                    className="flex justify-center items-center sm:w-7 sm:h-7 w-6 h-6"
                    style={{ display: "inline-block" }}
                  >
                    <ColorRing
                      visible={true}
                      height="100%" // Matches the responsive container
                      width="100%" // Matches the responsive container
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={[
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                      ]}
                    />
                  </div>
                ) : otpSent ? (
                  "Verify OTP"
                ) : (
                  "Login"
                )}
              </button>
            </form>
            <div className="flex flex-col  items-center">
              <p className="sm:text-lg text-base font-medium text-center">
                Click here to login using Google/FB account
              </p>
              <div className="flex gap-x-4">
                <LazyLoadImage
                  effect="blur"
                  src="/google.png"
                  alt="Google"
                  className="sm:w-8 sm:h-8 w-7 h-7 cursor-pointer transition-all duration-300  hover:scale-110"
                  onClick={handleGoogleSignIn}
                />

                <LazyLoadImage
                  effect="blur"
                  src="/facebook.png"
                  alt="facebook"
                  className="sm:w-8 sm:h-8 w-7 h-7  cursor-pointer transition-all duration-300  hover:scale-110"
                  onClick={handleFacebookSignIn}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Mobile View: Looking for hiring? Section */}
        <div className=" font-semibold text-base mt-6 lg:mt-0 mb-3">
          <p className="text-black">Looking for a hiring?</p>
          <button
            className="text-blue-200 transition-all duration-300 hover:text-blue-300 hover:scale-105"
            onClick={() => navigate("/Desktop-3")}
          >
            Click Here
          </button>
        </div>

        {/* ------- 3rd section --- */}

        {/* <div className="hidden xmd:flex flex-col gap-y-12">

            <h2 className="text-center font-semibold text-blue-200 text-3xl">Companies that hire from us</h2>
            
            <div className="grid xl:grid-cols-7 lg:grid-cols-6 grid-cols-5 justify-items-center  gap-x-4 gap-y-12">
              {companies.map((logo, index) => (
                <div key={index} className="w-32 h-32">
                  <LazyLoadImage
                    effect="blur"
                    src={`/companies logo/${logo}`}
                    alt="company-logo"
                    className="w-full h-full object-contain rounded-full"
                  />
                </div>
              ))}
            </div>
          </div>

               {
                companies.map((logo , index)=> (

                  <div
                  key={index}
                   className="w-32 h-32">

                    <img
                  src={`/companies logo/${logo}`}
                  alt="company-logo"
                  className="w-full h-full object-contain rounded-full"
                    />

                  </div>
                ))
               }

            </div>

          </div> */}
      </div>
      <HorizontalScroll data="Company that hire from us" className="w-full" />
    </section>
  );
};

export default CompanySection;
